export const P_APP_CODE_NAME = {
  A00001: '타임라인',
  A00002: '공지사항',
  A00003: '자유게시판',
  A00005: '1:1 문의 게시판',
  A00006: '질문 답변 게시판',
  A00009: '구독자에게 질문!',
  B00001: '아티클',
  B00002: '아웃링크',
  B00005: '포토북',
  B00006: '웰컴 페이지',
  B00012: '리소스 스토어',
  C00001: '기본 VOD',
  C00002: 'OTT형 VOD',
  C00004: '상담용 화상통화',
  C00005: '이벤트용 라이브',
  C00006: '연재형 VOD',
  C00007: '강의용 VOD',
  C00012: '1:1 화상통화',
  C00014: '커머스용 라이브',
  C00015: '숏츠VOD',
  D00003: '오픈챗',
  E00001: '상담 예약',
  F00001: '알림 메세지 발송',
  X00001: '페이지',
  X00002: '커머스',
  Z00001: '상담예약EXT'
} as const;
