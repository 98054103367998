class ObjectHelperImpl {
  static extend<T, U>(obj: T, lambdas: { [key: string]: (a: T) => U }) {
    const source = Object.entries(lambdas).reduce(
      (acc, [key, func]) => {
        acc[key] = func(obj);

        return acc;
      },
      {} as { [key: string]: U }
    );

    return {
      ...obj,
      ...source
    };
  }

  static omit<T extends Record<string, unknown>>(obj: T, keys: keyof T): Omit<T, keyof T>;
  static omit<T extends Record<string, unknown>, K extends [...(keyof T)[]]>(
    obj: T,
    keys: K
  ): {
    [K2 in Exclude<keyof T, K[number]>]: T[K2];
  };
  static omit(obj: any, keys: any) {
    const keySet = new Set(Array.isArray(keys) ? keys : [keys]);
    const validKeys = Object.keys(obj).filter((key) => !keySet.has(key));

    return this.pick(obj, validKeys);
  }

  static pick<T extends Record<string, unknown>>(obj: T, keys: keyof T): Pick<T, keyof T>;
  static pick<T extends Record<string, unknown>, K extends [...(keyof T)[]]>(
    obj: T,
    keys: K
  ): {
    [K2 in Extract<keyof T, K[number]>]: T[K2];
  };
  static pick<T>(obj: T, keys: keyof T | (keyof T)[]) {
    if (Array.isArray(keys)) {
      return keys.reduce((acc, key) => {
        if (obj[key] != null) {
          acc[key] = obj[key];
        }

        return acc;
      }, {} as T);
    }

    return obj[keys];
  }
}

export default ObjectHelperImpl;
