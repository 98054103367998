import classNames from 'classnames';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { Button, ButtonGroup } from 'react-bootstrap';

import type { ApexOptions } from 'apexcharts';

export type BarChartDatum = {
  name: string;
  type: string;
  data: {
    x: string;
    y: string;
  }[];
};

type Props = {
  data: BarChartDatum[];
  formatYAxis?: (value: number) => string | string[];
};

export function BarChart({ data, formatYAxis }: Props) {
  const [barChartMode, setBarChartMode] = useState<'GROUPED' | 'STACKED'>('GROUPED');

  const apexBarChartOpts: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: barChartMode === 'STACKED',
      toolbar: {
        show: false
      }
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    stroke: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      tickPlacement: 'between',
      tickAmount: 8,
      labels: {
        rotate: 0
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      labels: {
        formatter: formatYAxis
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    grid: {
      borderColor: '#f1f3fa'
    }
  };

  return (
    <>
      <ButtonGroup className="ms-1 mt-1 mb-1" size="sm">
        <Button
          variant={barChartMode === 'GROUPED' ? 'primary' : 'outline-primary'}
          className="btn-icon"
          onClick={() => setBarChartMode('GROUPED')}
        >
          <i className={classNames('mdi', 'mdi-chart-bar', 'ms-1', 'me-1')} />
        </Button>
        <Button
          variant={barChartMode === 'STACKED' ? 'primary' : 'outline-primary'}
          className="btn-icon"
          onClick={() => setBarChartMode('STACKED')}
        >
          <i className={classNames('mdi', 'mdi-chart-bar-stacked', 'ms-1', 'me-1')} />
        </Button>
      </ButtonGroup>

      <Chart
        height="330px"
        options={apexBarChartOpts}
        series={data}
        type="line"
        className="apex-charts"
      />
    </>
  );
}
