import { QueryKey as OriginQueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QUERY_KEY } from '@query/keys';
import { ApiRepository } from '@repositories/api';

import type {
  GetPublServiceChargeEstimatedSalesRequestPayload,
  PublServiceChargeEstimatedSalesResponseError,
  PublServiceChargeEstimatedSalesResponseOK
} from '@repositories/api/dashboard.types';
import type { AxiosResponse } from 'axios';

type Props<Data extends AxiosResponse, ErrorResponse, QueryKey extends OriginQueryKey> = {
  queryParams: GetPublServiceChargeEstimatedSalesRequestPayload['queryParams'];
  options?: Omit<
    UseQueryOptions<Data, ErrorResponse, Data['data']['data'], QueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  >;
};

function useGetPublDailyServiceChargeEstimatedSalesQuery<
  Data extends PublServiceChargeEstimatedSalesResponseOK,
  ErrorResponse extends PublServiceChargeEstimatedSalesResponseError
>({
  queryParams,
  options
}: Props<
  Data,
  ErrorResponse,
  ReturnType<typeof QUERY_KEY.DASHBOARD.GET_PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES>
>) {
  return useQuery({
    queryKey: QUERY_KEY.DASHBOARD.GET_PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES({ queryParams }),
    queryFn: () => {
      return ApiRepository.dashboard.getPublDailyServiceChargeEstimatedSales({ queryParams });
    },
    select: (data) => data.data.data,
    ...options
  });
}

export { useGetPublDailyServiceChargeEstimatedSalesQuery };
