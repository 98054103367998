import api, { getEndpointSet } from '@services/api';

import type {
  GetChannelAccessListRequestPayload,
  GetChannelCountListRequestPayload,
  GetDailyActiveUsersListRequestPayload,
  GetMonthlyActiveUsersListRequestPayload,
  GetPAppAdoptionRateListRequestPayload,
  GetSellerCountListRequestPayload
} from './statistics.types';

const {
  FIND_ALL_CHANNEL_COUNT,
  FIND_ALL_CHANNEL_ACCESS,
  FIND_ALL_SELLER_COUNT,
  FIND_ALL_DAILY_ACCESS_USERS,
  FIND_ALL_MONTHLY_ACCSEE_USERS,
  FIND_ALL_P_APP_ADOPTION_RATE
} = getEndpointSet('HUB', 'statistics');

class StatisticsRepository {
  static getChannelCountList({ queryParams }: GetChannelCountListRequestPayload) {
    return api.get(FIND_ALL_CHANNEL_COUNT, {}, queryParams);
  }

  static getChannelAccessList({ queryParams }: GetChannelAccessListRequestPayload) {
    return api.get(FIND_ALL_CHANNEL_ACCESS, {}, queryParams);
  }

  static getSellerCountList({ queryParams }: GetSellerCountListRequestPayload) {
    return api.get(FIND_ALL_SELLER_COUNT, {}, queryParams);
  }

  static getDailyActiveUsersList({ queryParams }: GetDailyActiveUsersListRequestPayload) {
    return api.get(FIND_ALL_DAILY_ACCESS_USERS, {}, queryParams);
  }

  static getMonthlyActiveUsersList({ queryParams }: GetMonthlyActiveUsersListRequestPayload) {
    return api.get(FIND_ALL_MONTHLY_ACCSEE_USERS, {}, queryParams);
  }

  static getPAppAdoptionRateList({ queryParams }: GetPAppAdoptionRateListRequestPayload) {
    return api.get(FIND_ALL_P_APP_ADOPTION_RATE, {}, queryParams);
  }
}

export default StatisticsRepository;
