import classNames from 'classnames';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { HyperDatepicker } from '@components';
import { DateHelper } from '@utils/DateHelper';

type FormValues = {
  channelId: string;
  startAt: Date;
  endAt: Date;
};

type Props = {
  channelId: string | null;
  startAt: string;
  endAt: string;
  onSearch?: (data: { channelId: string; startAt: string; endAt: string }) => void;
};

export function SearchBar({ channelId, startAt, endAt, onSearch }: Props) {
  const methods = useForm<FormValues>({
    defaultValues: {
      channelId: channelId || '',
      startAt: new Date(startAt),
      endAt: new Date(endAt)
    },
    mode: 'onChange'
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { isValid }
  } = methods;

  const onSubmit = (data: Required<FormValues>) => {
    if (onSearch) {
      onSearch({
        channelId: data.channelId,
        startAt: new DateHelper(data.startAt).format('YYYY-MM-DD'),
        endAt: new DateHelper(data.endAt).format('YYYY-MM-DD')
      });
    }
  };

  return (
    <Form className="d-flex align-items-end" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group style={{ width: 150 }}>
        <Form.Label>채널 ID</Form.Label>
        <Form.Control {...register('channelId', { required: true })} />
      </Form.Group>
      <Form.Group className="ms-2" style={{ width: 150 }}>
        <Form.Label>Start Date</Form.Label>
        <HyperDatepicker name="startAt" control={control} inputClass="form-control" />
      </Form.Group>
      <Form.Group className="ms-2" style={{ width: 150 }}>
        <Form.Label>End Date</Form.Label>
        <HyperDatepicker name="endAt" control={control} inputClass="form-control" />
      </Form.Group>
      <div className="ms-2">
        <Button type="submit" className={classNames({ disabled: !isValid })}>
          Submit
        </Button>
      </div>
    </Form>
  );
}
