import {
  revenueFlowEstimationPath,
  pAppAdoptionRatePath,
  statisticsPath,
  channelCountsPath,
  dailyActiveUsersPath,
  dailySellerChannelAccessPath,
  monthlyActiveUsersPath,
  sellerCountsPath
} from './routes';

export type MenuItemType = {
  key: string;
  label: string;
  isTitle?: boolean;
  icon?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  parentKey?: string;
  target?: string;
  children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
  { key: 'navigation', label: 'Navigation', isTitle: true },
  {
    key: 'dashboards',
    label: 'Dashboards',
    isTitle: false,
    icon: 'uil-home-alt',
    children: [
      {
        key: 'revenue-flow-estimation',
        label: '거래액 및 매출 흐름 추정',
        url: revenueFlowEstimationPath,
        parentKey: 'dashboards'
      },
      {
        key: 'statistics',
        label: '통계',
        url: statisticsPath,
        parentKey: 'dashboards',
        children: [
          {
            key: 'channel-counts',
            label: '채널 수',
            url: channelCountsPath,
            parentKey: 'statistics'
          },
          {
            key: 'seller-counts',
            label: '셀러 수',
            url: sellerCountsPath,
            parentKey: 'statistics'
          },
          {
            key: 'daily-active-users',
            label: 'DAU',
            url: dailyActiveUsersPath,
            parentKey: 'statistics'
          },
          {
            key: 'monthly-active-users',
            label: 'MAU',
            url: monthlyActiveUsersPath,
            parentKey: 'statistics'
          },
          {
            key: 'daily-seller-channel-access',
            label: '일별 셀러 채널 접근',
            url: dailySellerChannelAccessPath,
            parentKey: 'statistics'
          },
          {
            key: 'p-app-adoption-rate',
            label: 'pApp 채택률',
            url: pAppAdoptionRatePath,
            parentKey: 'statistics'
          }
        ]
      }
    ]
  }
];

export { MENU_ITEMS };
