import classNames from 'classnames';
import React, { useState } from 'react';

import { getVisiblePages } from './utils';

export type PageSize = {
  text: string;
  value: number;
};

export type PaginationProps = {
  currentPage: number;
  totalPage: number;
  currentPageSize: number;
  pageSizeList: PageSize[];
  changePageSize?: (pageSize: number) => void;
  movePage?: (page: number) => void;
  movePrevPage?: () => void;
  moveNextPage?: () => void;
};

function Pagination({
  currentPage,
  totalPage,
  currentPageSize,
  pageSizeList,
  changePageSize,
  movePage,
  movePrevPage,
  moveNextPage
}: PaginationProps) {
  const [directPageMoveInputValue, setDirectPageMoveInputValue] = useState<number | string>(
    currentPage
  );
  const visiblePages = getVisiblePages(currentPage, totalPage);

  const handlePageSizeSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (changePageSize) {
      changePageSize(Number(e.target.value));
    }
  };

  const handleDirectPageMoveInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirectPageMoveInputValue(e.target.value);
  };

  const handleDirectPageMoveInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    let destinationPage = value;

    if (totalPage < value) {
      destinationPage = totalPage;
    }

    if (value < 1) {
      destinationPage = 1;
    }

    setDirectPageMoveInputValue(destinationPage);

    if (movePage) {
      movePage(destinationPage);
    }
  };

  const handlePageButtonChange = (page: number) => {
    setDirectPageMoveInputValue(page);

    if (movePage) {
      movePage(page);
    }
  };

  const handlePrevPageButtonClick = () => {
    setDirectPageMoveInputValue(currentPage - 1);

    if (movePrevPage) {
      movePrevPage();
    }
  };

  const handleNextPageButtonClick = () => {
    setDirectPageMoveInputValue(currentPage + 1);

    if (moveNextPage) {
      moveNextPage();
    }
  };

  return (
    <div className="d-lg-flex align-items-center text-center pb-1">
      <div className="d-inline-block me-3">
        <span className="fw-semibold me-1">Display :</span>
        <select
          role="button"
          value={currentPageSize}
          onChange={handlePageSizeSelectChange}
          className="form-select d-inline-block w-auto"
        >
          {pageSizeList.map((pageSize, index) => {
            return (
              <option key={index.toString()} value={pageSize.value}>
                {pageSize.text}
              </option>
            );
          })}
        </select>
      </div>

      <div className="me-3">
        <span className="fw-semibold me-1">Page</span>
        <strong>
          {currentPage} of {totalPage}
        </strong>
      </div>

      <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2">
        <span className="fw-semibold me-1">Go to page :</span>
        <input
          type="number"
          value={directPageMoveInputValue}
          min="1"
          onChange={handleDirectPageMoveInputChange}
          onBlur={handleDirectPageMoveInputBlur}
          className="form-control w-25 d-inline-block"
        />
      </span>

      <ul className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
        <div
          key="prevpage"
          className={classNames('page-item', 'paginate_button', 'previous', {
            disabled: currentPage <= 1
          })}
          onClick={handlePrevPageButtonClick}
        >
          <div role="button" className="page-link">
            <i className="mdi mdi-chevron-left" />
          </div>
        </div>
        {visiblePages.map((page, index, array) => {
          return array[index - 1] + 1 < page ? (
            <React.Fragment key={page.toString()}>
              <div className="page-item disabled d-none d-xl-inline-block">
                <div role="button" className="page-link">
                  ...
                </div>
              </div>
              <div
                className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                  active: currentPage === page
                })}
                onClick={() => handlePageButtonChange(page)}
              >
                <div role="button" className="page-link">
                  {page}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div
              key={page.toString()}
              className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                active: currentPage === page
              })}
              onClick={() => handlePageButtonChange(page)}
            >
              <div role="button" className="page-link">
                {page}
              </div>
            </div>
          );
        })}
        <div
          key="nextpage"
          className={classNames('page-item', 'paginate_button', 'next', {
            disabled: currentPage >= totalPage
          })}
          onClick={handleNextPageButtonClick}
        >
          <div role="button" className="page-link">
            <i className="mdi mdi-chevron-right" />
          </div>
        </div>
      </ul>
    </div>
  );
}

export { Pagination };
