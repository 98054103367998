export function getVisiblePages(page: number, total: number) {
  if (total < 7) {
    return Array(total)
      .fill(null)
      .map((_, index) => index + 1);
  }

  if (page % 5 >= 0 && page > 4 && page + 3 < total) {
    return [1, page - 1, page, page + 1, total];
  }

  if (page % 5 >= 0 && page > 4 && page + 3 >= total) {
    return [1, total - 4, total - 3, total - 2, total - 1, total];
  }

  return [1, 2, 3, 4, 5, total];
}
