import { HUB_HOST } from '@config';
import { EnumOfObjects } from '@utils';

import { endpoints } from './manifest';

import type { Method } from 'axios';

type Permission = 'private' | 'public';

export type Endpoint<T = Method> = {
  method: T;
  path: string;
  permission: Permission;
};

type Endpoints = typeof endpoints;
type ServerName = keyof Endpoints;
type Domain = keyof Endpoints[keyof Endpoints];

export function getEndpointSet<T extends ServerName, U extends Domain>(serverName: T, domain: U) {
  const host = getHostByServerName(serverName);
  const serverInterface = endpoints[serverName][domain];

  return EnumOfObjects.assignAll(serverInterface, { host });
}

export function getHostByServerName(serverName: keyof Endpoints) {
  switch (serverName) {
    case 'HUB':
      return HUB_HOST;
    default:
      return '';
  }
}
