import { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { HyperDatepicker } from '@components';
import { DateHelper, generateUrl } from '@utils';

import { validateQueryParams } from '../utils';

type FormValues = {
  exchangeRate: string | null;
  startDate: Date;
  endDate: Date;
};

export function HeaderBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const exchangeRate = searchParams.get('exchangeRate');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');

  const methods = useForm<FormValues>({
    defaultValues: {
      exchangeRate,
      startDate: new Date(startDate as any),
      endDate: new Date(endDate as any)
    }
  });

  const { handleSubmit, register, control, setValue } = methods;

  useEffect(() => {
    const queryParams = { exchangeRate, startDate, endDate };
    const isValidQueryParams = validateQueryParams(queryParams);

    if (isValidQueryParams) {
      setValue('exchangeRate', queryParams.exchangeRate);
      setValue('startDate', new Date(queryParams.startDate));
      setValue('endDate', new Date(queryParams.endDate));
    }
  }, [exchangeRate, startDate, endDate]);

  const isValidQueryParams = validateQueryParams({ exchangeRate, startDate, endDate });

  if (!isValidQueryParams) {
    const defaultQueryParams = {
      exchangeRate: '1100',
      startDate: new DateHelper().subtract(2, 'W').format('YYYY-MM-DD'),
      endDate: new DateHelper().format('YYYY-MM-DD')
    };

    return <Navigate to={generateUrl(location.pathname, {}, defaultQueryParams)} />;
  }

  const onSubmit = (data: FormValues) => {
    const currentQueryParams = {
      exchangeRate: data.exchangeRate,
      startDate: new DateHelper(data.startDate).format('YYYY-MM-DD'),
      endDate: new DateHelper(data.endDate).format('YYYY-MM-DD')
    };

    const isValidQueryParams = validateQueryParams(currentQueryParams);

    if (isValidQueryParams) {
      navigate(generateUrl(location.pathname, {}, currentQueryParams));
    }
  };

  return (
    <div className="page-title-box">
      <div className="page-title-right mt-1">
        <Form className="d-flex align-items-end" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group style={{ width: 150 }}>
            <Form.Label>Exchange Rate</Form.Label>
            <Form.Control type="number" {...register('exchangeRate', { required: true })} />
          </Form.Group>
          <Form.Group className="ms-2" style={{ width: 150 }}>
            <Form.Label>Start Date</Form.Label>
            <HyperDatepicker name="startDate" control={control} inputClass="form-control" />
          </Form.Group>
          <Form.Group className="ms-2" style={{ width: 150 }}>
            <Form.Label>End Date</Form.Label>
            <HyperDatepicker name="endDate" control={control} inputClass="form-control" />
          </Form.Group>
          <div className="ms-2">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
      <h4 className="page-title">거래액 및 매출 흐름 추정</h4>
    </div>
  );
}
