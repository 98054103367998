import { useLocation } from 'react-router-dom';

import { DateHelper } from '@utils/DateHelper';

export function useQueryParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const date = searchParams.get('date');

  const isValidDate = new DateHelper().validate(date);

  const isInValidQueryParams = !isValidDate;

  if (isInValidQueryParams) {
    return {
      date,
      isInValidQueryParams
    };
  }

  return {
    date,
    isInValidQueryParams
  };
}
