import { useEffect } from 'react';
import { Card } from 'react-bootstrap';

import { Error, Loading } from '@components';
import { useGetPublDailyTotalSalesQuery } from '@query/dashboard/hooks';
import { numberWithCommas } from '@utils';

import { applyExchageRate, generateBarChartData } from '../utils';

import { BarChart } from './BarChart';
import { DataTable } from './DataTable';
import { Tabs } from './Tabs';

type Props = {
  startAt: string;
  endAt: string;
  exchangeRate: string;
  isEnabled: boolean;
  onFetchComplete: () => void;
};

export function PublDailyTotalSales({
  startAt,
  endAt,
  exchangeRate,
  isEnabled,
  onFetchComplete
}: Props) {
  const { data, isLoading, isFetching, isError, refetch } = useGetPublDailyTotalSalesQuery({
    queryParams: {
      startAt,
      endAt,
      exchangeRate,
      orderBy: 'ASC'
    },
    options: {
      enabled: isEnabled,
      onSettled: onFetchComplete
    }
  });

  useEffect(() => {
    if (isEnabled) {
      refetch();
    }
  }, [isEnabled]);

  const makePreprocessedData = (responseData: NonNullable<typeof data>['totalSalesList']) => {
    return responseData.map(
      ({
        date,
        SUBSCRIPTION_PRODUCT_PURCHASE_KRW_RS,
        SUBSCRIPTION_PRODUCT_PURCHASE_USD_RS,
        P_APP_UNLOCK_PRODUCT_PURCHASE_KRW_RS,
        P_APP_UNLOCK_PRODUCT_PURCHASE_USD_RS,
        CHANNEL_PLAN_PRODUCT_PURCHASE_KRW,
        CHANNEL_PLAN_PRODUCT_PURCHASE_USD
      }) => ({
        date,
        SUBSCRIPTION_PRODUCT_PURCHASE: String(
          Number(SUBSCRIPTION_PRODUCT_PURCHASE_KRW_RS) +
            applyExchageRate(SUBSCRIPTION_PRODUCT_PURCHASE_USD_RS, exchangeRate)
        ),
        P_APP_UNLOCK_PRODUCT_PURCHASE: String(
          Number(P_APP_UNLOCK_PRODUCT_PURCHASE_KRW_RS) +
            applyExchageRate(P_APP_UNLOCK_PRODUCT_PURCHASE_USD_RS, exchangeRate)
        ),
        CHANNEL_PLAN_PRODUCT_PURCHASE: String(
          Number(CHANNEL_PLAN_PRODUCT_PURCHASE_KRW) +
            applyExchageRate(CHANNEL_PLAN_PRODUCT_PURCHASE_USD, exchangeRate)
        )
      })
    );
  };

  return (
    <Card>
      <Card.Body>
        <Tabs
          title="일별 publ 총 매출"
          contentsHeight="400px"
          tabItems={[
            {
              id: 'BAR_CHART',
              title: 'Bar Chart',
              content: (
                <>
                  {isError ? (
                    <Error />
                  ) : isLoading || isFetching ? (
                    <Loading />
                  ) : (
                    <BarChart
                      data={generateBarChartData({
                        type: 'bar',
                        keys: [
                          'SUBSCRIPTION_PRODUCT_PURCHASE',
                          'P_APP_UNLOCK_PRODUCT_PURCHASE',
                          'CHANNEL_PLAN_PRODUCT_PURCHASE'
                        ],
                        labels: {
                          SUBSCRIPTION_PRODUCT_PURCHASE: '구독상품 매출액 (B+C)',
                          P_APP_UNLOCK_PRODUCT_PURCHASE: '유료이용권 매출액 (D+E)',
                          CHANNEL_PLAN_PRODUCT_PURCHASE: '채널 플랜 매출 추정 (G+H)'
                        },
                        data: makePreprocessedData(data.totalSalesList)
                      })}
                      formatYAxis={(value) => {
                        const checkedValue = isFinite(value) ? value : 0;

                        return numberWithCommas(checkedValue);
                      }}
                    />
                  )}
                </>
              )
            },
            {
              id: 'DATA_TABLE',
              title: 'Data Table',
              content: (
                <>
                  {isError ? (
                    <Error />
                  ) : isLoading || isFetching ? (
                    <Loading />
                  ) : (
                    <DataTable
                      data={[...data.totalSalesList].reverse()}
                      columns={[
                        { header: '날짜', field: 'date' },
                        {
                          header: '구독상품 매출액KRW (B)',
                          field: 'SUBSCRIPTION_PRODUCT_PURCHASE_KRW_RS',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '구독상품 매출액USD (C)',
                          field: 'SUBSCRIPTION_PRODUCT_PURCHASE_USD_RS',
                          formatFiled: (value) => numberWithCommas(value, 2)
                        },
                        {
                          header: '유료이용권 매출액KRW (D)',
                          field: 'P_APP_UNLOCK_PRODUCT_PURCHASE_KRW_RS',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '유료이용권 매출액USD (E)',
                          field: 'P_APP_UNLOCK_PRODUCT_PURCHASE_USD_RS',
                          formatFiled: (value) => numberWithCommas(value, 2)
                        },
                        {
                          header: '총 CP 소비 매출 (F)',
                          field: 'TOTAL_CP_CONSUMPTION_SALES',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '채널 플랜 매출 추정KRW (G)',
                          field: 'CHANNEL_PLAN_PRODUCT_PURCHASE_KRW',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '채널 플랜 매출 추정USD (H)',
                          field: 'CHANNEL_PLAN_PRODUCT_PURCHASE_USD',
                          formatFiled: (value) => numberWithCommas(value, 2)
                        },
                        {
                          header: '총 매출액KRW (I = B+D+F+G)',
                          field: 'TOTAL_SALES_AMOUNT_KRW',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '총 매출액USD (J = C+E+H)',
                          field: 'TOTAL_SALES_AMOUNT_USD',
                          formatFiled: (value) => numberWithCommas(value, 2)
                        },
                        {
                          header: '추정 총 매출액 (I + J*환율)',
                          field: 'ESTIMATED_TOTAL_EXPECTED_PROFIT',
                          formatFiled: (value) => numberWithCommas(value)
                        }
                      ]}
                    />
                  )}
                </>
              )
            }
          ]}
        />
      </Card.Body>
    </Card>
  );
}
