import { generatePath } from 'react-router-dom';

type RouteParams<RoutePath extends string> = {
  [K in keyof ExtractRouteParams<RoutePath>]: string;
};

type ExtractRouteParams<RoutePath extends string> =
  RoutePath extends `${string}/:${infer Param}/${infer Rest}`
    ? { [K in Param | keyof ExtractRouteParams<Rest>]: string }
    : RoutePath extends `${string}/:${infer Param}`
    ? { [K in Param]: string }
    : Record<string, unknown>;

function filterNonStringAndNumberAndBoolean<T extends Record<string, unknown>>(object: T) {
  return Object.fromEntries(
    Object.entries(object)
      .filter(
        ([, value]) =>
          typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean'
      )
      .map((entry) => [entry[0], String(entry[1])])
  );
}

function checkEmptyObject(object: ReturnType<typeof filterNonStringAndNumberAndBoolean>) {
  return Object.values(object).length === 0 && object.constructor === Object;
}

/**
 * path, pathVariables, queryParams를 URL로 만들어주는 함수입니다.
 * @param path '/channels/:channelCode'
 * @param pathVariables { channelCode: 'IGIEWEKF141FWAF1F' }
 * @param queryParams { from: 'conversionLink', limit: '1' } / { from: undefined }
 * @returns '/channels/IGIEWEKF141FWAF1F?from=conversionLink&limit=1'
 * @example
 *
 * const path = '/channels/:channelCode';
 * const pathVariables = { channelCode: 'IGIEWEKF141FWAF1F' };
 * const queryParams = { from: 'conversionLink', limit: '1' };
 *
 * generateUrl(path, pathVariables, queryParams);
 */
function generateUrl<T extends string>(
  path: T,
  pathVariables: RouteParams<T>,
  queryParams?: { [key: string]: unknown }
) {
  const filteredQueryParams = queryParams && filterNonStringAndNumberAndBoolean(queryParams);

  if (!filteredQueryParams || checkEmptyObject(filteredQueryParams)) {
    return generatePath(path, pathVariables);
  }

  const generatedQueryParams = new URLSearchParams(filteredQueryParams).toString();

  return `${generatePath(path, pathVariables)}?${generatedQueryParams}`;
}

export { generateUrl };
