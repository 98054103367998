import { generatePath, Params } from 'react-router-dom';

import { KEY_ACCESS_TOKEN } from '@config';
import { JWT, Request as AxiosRequest } from '@utils';

import RESPONSES from './responses.json';

import type { Endpoint } from './endpoints';
import type { Call } from '@utils/request/request';
import type { Method } from 'axios';

const { RESP_AXIOS_UNAUTHENTICATED } = RESPONSES;

type EndpointWithHost<T = Method> = Endpoint<T> & {
  host: string;
};

type PathVariables = Params;
type QueryParams = Call['params'];
type Body = Call['body'];
type Options = Call['options'];

type Request = {
  endpoint: EndpointWithHost;
  pathVariables?: PathVariables;
  queryParams?: QueryParams;
  body?: Body;
  options?: Options;
};

class Api {
  token: string;
  axiosRequest: AxiosRequest;

  constructor() {
    const { status, token } = JWT.validateJwtToken(localStorage.getItem(KEY_ACCESS_TOKEN));

    this.token = status === 'OK' ? token : '';

    this.axiosRequest = new AxiosRequest();
  }

  applyCredential(credentials: { token: string }) {
    const { token } = credentials;

    this.setToken(token);
  }

  purgeCredential() {
    this.unsetToken();
  }

  private setToken(token: string) {
    this.token = token;
    localStorage.setItem(KEY_ACCESS_TOKEN, token);
  }

  private unsetToken() {
    this.token = '';
    localStorage.removeItem(KEY_ACCESS_TOKEN);
  }

  get(
    endpoint: EndpointWithHost<'GET'>,
    pathVariables?: PathVariables,
    queryParams?: QueryParams,
    options?: Options
  ) {
    return this.request({ endpoint, pathVariables, queryParams, options });
  }

  post(
    endpoint: EndpointWithHost<'POST'>,
    pathVariables?: PathVariables,
    body?: Body,
    options?: Options
  ) {
    return this.request({ endpoint, pathVariables, body, options });
  }

  put(
    endpoint: EndpointWithHost<'PUT'>,
    pathVariables?: PathVariables,
    body?: Body,
    options?: Options
  ) {
    return this.request({ endpoint, pathVariables, body, options });
  }

  patch(
    endpoint: EndpointWithHost<'PATCH'>,
    pathVariables?: PathVariables,
    body?: Body,
    options?: Options
  ) {
    return this.request({ endpoint, pathVariables, body, options });
  }

  delete(endpoint: EndpointWithHost<'DELETE'>, pathVariables?: PathVariables, options?: Options) {
    return this.request({ endpoint, pathVariables, options });
  }

  private async request({
    endpoint,
    pathVariables,
    queryParams,
    body,
    options = {}
  }: Request): Promise<any> {
    if (endpoint.permission === 'private') {
      const { status, token } = JWT.validateJwtToken(this.token);

      if (status === 'ERROR') {
        this.unsetToken();

        throw RESP_AXIOS_UNAUTHENTICATED;
      }

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`
      };
    }

    return this.axiosRequest.call({
      method: endpoint.method,
      url: endpoint.host + generatePath(endpoint.path, pathVariables),
      params: queryParams,
      body,
      options
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Api();
