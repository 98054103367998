import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { useAuthValidateQuery } from '@query/auth/hooks';
import { authAtom } from '@store/atoms/auth';

function Splash() {
  const setIsAutoSignInExecuted = useSetAtom(authAtom.isAutoSignInExecuted);
  const setIsAuthenticated = useSetAtom(authAtom.isAuthenticated);

  const { isSuccess, isFetched } = useAuthValidateQuery({});

  useEffect(() => {
    if (isSuccess) {
      setIsAuthenticated(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isFetched) {
      setIsAutoSignInExecuted(true);
    }
  }, [isFetched]);

  return null;
}

export default Splash;
