import classNames from 'classnames';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';

type DatepickerInputProps = {
  onClick?: () => void;
  value?: string;
};

/* Datepicker with Input */
const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => {
  const onDateValueChange = () => {};
  return (
    <input
      type="text"
      className="form-control date"
      onClick={props.onClick}
      value={props.value}
      onChange={onDateValueChange}
      ref={ref}
    />
  );
});
DatepickerInput.displayName = 'DatepickerInput';

/* Datepicker with Addon Input */
const DatepickerInputWithAddon = forwardRef<HTMLInputElement, DatepickerInputProps>(
  (props, ref) => (
    <div className="input-group" ref={ref} onClick={props.onClick}>
      <input
        type="text"
        className="form-control form-control-light"
        value={props.value}
        readOnly
        style={{ cursor: 'pointer' }}
      />
      <div className="input-group-append">
        <span className="input-group-text bg-primary border-primary text-white">
          <i className="mdi mdi-calendar-range font-13" />
        </span>
      </div>
    </div>
  )
);
DatepickerInputWithAddon.displayName = 'DatepickerInputWithAddon';

type HyperDatepickerProps = {
  name: string;
  control: Control<any, any>;
  onChange?: (date: Date) => void;
  hideAddon?: boolean;
  inputClass: string;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  showTimeSelect?: boolean;
  tI?: number;
  timeFormat?: string;
  timeCaption?: string;
  showTimeSelectOnly?: boolean;
  showMonthYearPicker?: boolean;
  monthsShown?: number;
  inline?: boolean;
};

export function HyperDatepicker({
  name,
  control,
  onChange,
  hideAddon,
  inputClass,
  dateFormat,
  minDate,
  maxDate,
  showTimeSelect,
  tI,
  timeFormat,
  timeCaption,
  showTimeSelectOnly,
  showMonthYearPicker,
  monthsShown,
  inline
}: HyperDatepickerProps) {
  // handle custom input
  const input = (hideAddon || false) === true ? <DatepickerInput /> : <DatepickerInputWithAddon />;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange: onControllerChange } }) => (
          <DatePicker
            customInput={input}
            timeIntervals={tI}
            className={classNames('form-control', inputClass)}
            selected={value}
            onChange={(date, event) => {
              onControllerChange(date, event);

              if (onChange && date) {
                onChange(date);
              }
            }}
            showTimeSelect={showTimeSelect}
            timeFormat={timeFormat || 'hh:mm a'}
            timeCaption={timeCaption}
            dateFormat={dateFormat || 'yyyy/MM/dd'}
            minDate={minDate}
            maxDate={maxDate}
            monthsShown={monthsShown}
            showTimeSelectOnly={showTimeSelectOnly}
            showMonthYearPicker={showMonthYearPicker}
            inline={inline}
            autoComplete="off"
          />
        )}
      />
    </>
  );
}
