type Stringable = string | number | bigint | boolean | null | undefined;

type Join<A, Sep extends string = ''> = A extends [infer First, ...infer Rest]
  ? Rest extends []
    ? `${First & Stringable}`
    : `${First & Stringable}${Sep}${Join<Rest, Sep>}`
  : '';

/**
 * 상대 경로 path들을 join 해주는 함수 입니다.
 * @param paths join하려는 path들 입니다. 첫번 째 path는 앞에 '/'가 있어야합니다.
 * @returns '/dashboards/statistics'
 * @example
 *
 * const DASHBOARDS = '/dashbards'
 * const STATISTICS = 'statistics'
 *
 * generateRoutePath(DASHBOARDS, STATISTICS); // '/dashboards/statistics'
 */
export function generateRoutePath<T extends string[]>(...paths: [...T]) {
  return paths.join('/') as Join<[...T], '/'>;
}
