import { LayoutActionTypes } from './constants';

import type {
  LayoutColor,
  LayoutTypes,
  LayoutWidth,
  SideBarTheme,
  SideBarType
} from '@appConstants';

export type LayoutActionType<TPayload> = {
  type:
    | LayoutActionTypes.CHANGE_LAYOUT
    | LayoutActionTypes.CHANGE_LAYOUT_COLOR
    | LayoutActionTypes.CHANGE_LAYOUT_WIDTH
    | LayoutActionTypes.CHANGE_SIDEBAR_THEME
    | LayoutActionTypes.CHANGE_SIDEBAR_TYPE
    | LayoutActionTypes.SHOW_RIGHT_SIDEBAR
    | LayoutActionTypes.HIDE_RIGHT_SIDEBAR;
  payload?: TPayload;
};

export const changeLayout = (layout: LayoutTypes): LayoutActionType<LayoutTypes> => ({
  type: LayoutActionTypes.CHANGE_LAYOUT,
  payload: layout
});

export const changeLayoutColor = (color: LayoutColor): LayoutActionType<LayoutColor> => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_COLOR,
  payload: color
});

export const changeLayoutWidth = (width: LayoutWidth): LayoutActionType<LayoutWidth> => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
  payload: width
});

export const changeSidebarTheme = (sidebarTheme: SideBarTheme): LayoutActionType<SideBarTheme> => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
  payload: sidebarTheme
});

export const changeSidebarType = (sidebarType: SideBarType): LayoutActionType<SideBarType> => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_TYPE,
  payload: sidebarType
});

export const showRightSidebar = (): LayoutActionType<null> => ({
  type: LayoutActionTypes.SHOW_RIGHT_SIDEBAR
});

export const hideRightSidebar = (): LayoutActionType<null> => ({
  type: LayoutActionTypes.HIDE_RIGHT_SIDEBAR
});
