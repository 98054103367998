import { Table } from 'react-bootstrap';

type Props = {
  columns: {
    header: string;
    field: string;
    formatFiled?: (value: string) => string;
  }[];
  data: Record<string, string>[];
};

export function DataTable({ columns, data }: Props) {
  return (
    <Table className="table-nowrap mb-0" hover>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.field}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((datum: Record<string, string>) => (
          <tr key={datum[columns[0].field]}>
            {columns.map(({ header, field, formatFiled = (value) => value }) => (
              <td key={datum[columns[0].field] + header}>{formatFiled(datum[field])}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
