import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { configureStore } from './redux/store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false
    }
  }
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={configureStore({})}>
      <App />
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
