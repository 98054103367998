import { useAtomValue } from 'jotai';
import { Navigate, useRoutes } from 'react-router-dom';

import {
  channelCountsPath,
  LayoutTypes,
  PATHNAMES,
  revenueFlowEstimationPath,
  signInPath
} from '@appConstants';
import { useRedux } from '@hooks';
import {
  DefaultLayout,
  VerticalLayout,
  HorizontalLayout,
  DetachedLayout,
  FullLayout
} from '@layouts';
import {
  ChannelCounts,
  DailySellerChannelAccess,
  DAU,
  MAU,
  PAppAdoptionRate,
  RevenueFlowEstimation,
  SellerCounts
} from '@pages/Dashboard';
import { SignIn, Verify } from '@pages/Root';
import { authAtom } from '@store/atoms/auth';

import PrivateRoute from './PrivateRoute';
import Splash from './Splash';

function AllRoutes() {
  const isAutoSignInExecuted = useAtomValue(authAtom.isAutoSignInExecuted);

  const { appSelector } = useRedux();

  const { layout } = appSelector((state) => ({
    layout: state.Layout
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  const Layout = getLayout();

  return useRoutes(
    !isAutoSignInExecuted
      ? [{ path: '*', element: <Splash /> }]
      : [
          {
            path: PATHNAMES.SIGN_IN,
            element: <DefaultLayout />,
            children: [{ index: true, element: <SignIn /> }]
          },
          {
            path: PATHNAMES.OAUTH,
            element: <DefaultLayout />,
            children: [
              {
                path: PATHNAMES.VERIFY,
                element: <Verify />
              }
            ]
          },
          {
            path: PATHNAMES.DASHBOARDS,
            element: <PrivateRoute component={Layout} />,
            children: [
              {
                path: PATHNAMES.REVENUE_FLOW_ESTIMATION,
                element: <RevenueFlowEstimation />
              },
              {
                path: PATHNAMES.STATISTICS,
                children: [
                  {
                    path: PATHNAMES.CHANNEL_COUNTS,
                    element: <ChannelCounts />
                  },
                  {
                    path: PATHNAMES.SELLER_COUNTS,
                    element: <SellerCounts />
                  },
                  {
                    path: PATHNAMES.DAU,
                    element: <DAU />
                  },
                  {
                    path: PATHNAMES.MAU,
                    element: <MAU />
                  },
                  {
                    path: PATHNAMES.DAILY_SELLER_CHANNEL_ACCESS,
                    element: <DailySellerChannelAccess />
                  },
                  {
                    path: PATHNAMES.P_APP_ADOPTION_RATE,
                    element: <PAppAdoptionRate />
                  },
                  {
                    index: true,
                    element: <Navigate replace to={{ pathname: channelCountsPath }} />
                  }
                ]
              },
              {
                index: true,
                element: <Navigate replace to={{ pathname: revenueFlowEstimationPath }} />
              }
            ]
          },
          {
            path: '*',
            element: <Navigate replace to={signInPath} />
          }
        ]
  );
}

export { AllRoutes };
