function validateQueryParams(queryParams: {
  exchangeRate: string | null;
  startDate: string | null;
  endDate: string | null;
}): queryParams is Record<keyof typeof queryParams, string> {
  const { exchangeRate, startDate, endDate } = queryParams;

  const hasEmptyQuery = [exchangeRate, startDate, endDate].some(
    (query) => query === null || query === ''
  );

  if (hasEmptyQuery) {
    return false;
  }

  if (Number.isNaN(Number(exchangeRate))) {
    return false;
  }

  if (Number.isNaN(Date.parse(String(startDate)))) {
    return false;
  }

  if (Number.isNaN(Date.parse(String(endDate)))) {
    return false;
  }

  return true;
}

export { validateQueryParams };
