type Template = 'YYYY-MM' | 'YYYY-MM-DD';
type Measurement = 'Y' | 'M' | 'W' | 'D';

class DateHelper {
  date = new Date();

  constructor(givenDate?: Date | string | number | null) {
    if (givenDate) {
      this.date = new Date(givenDate);
    }
  }

  add(value: number, measurement: Measurement) {
    const year = this.date.getFullYear();
    const month = this.date.getMonth();
    const date = this.date.getDate();

    switch (measurement) {
      case 'Y':
        return new DateHelper(this.date.setFullYear(year + value));
      case 'M':
        return new DateHelper(this.date.setMonth(month + value));
      case 'W':
        return new DateHelper(this.date.setDate(date + value * 7));
      case 'D':
        return new DateHelper(this.date.setDate(date + value));
    }
  }

  subtract(value: number, measurement: Measurement) {
    const year = this.date.getFullYear();
    const month = this.date.getMonth();
    const date = this.date.getDate();

    switch (measurement) {
      case 'Y':
        return new DateHelper(this.date.setFullYear(year - value));
      case 'M':
        return new DateHelper(this.date.setMonth(month - value));
      case 'W':
        return new DateHelper(this.date.setDate(date - value * 7));
      case 'D':
        return new DateHelper(this.date.setDate(date - value));
    }
  }

  format(template: Template) {
    const year = this.date.getFullYear();
    const month = String(this.date.getMonth() + 1).padStart(2, '0');
    const day = String(this.date.getDate()).padStart(2, '0');

    switch (template) {
      case 'YYYY-MM':
        return `${year}-${month}`;
      case 'YYYY-MM-DD':
        return `${year}-${month}-${day}`;
    }
  }

  validate<T extends Date | string | null>(value: T): value is NonNullable<T> {
    if (!value) {
      return false;
    }

    if (value instanceof Date && Number.isNaN(value.getDate())) {
      return false;
    }

    if (typeof value === 'string' && Number.isNaN(Date.parse(value))) {
      return false;
    }

    return true;
  }
}

export { DateHelper };
