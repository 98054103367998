/**
 * currency에 따라 숫자를 통화 형식으로 변환한다.
 *
 * @example
 * numberWithCommas(1024) // 1,024
 * numberWithCommas(1024, 2) // 1,024.00
 */
function numberWithCommas(value: number | string, digits = 0) {
  const [essence, decimal = ''] = String(value).split('.');

  const formattedEssence = essence.replace(/(\d)(?=(\d{3})+$)/g, '$1,');

  if (!digits) {
    return formattedEssence;
  }

  if (decimal.length > digits) {
    return `${formattedEssence}.${decimal.slice(digits)}`;
  }

  return `${formattedEssence}.${decimal.padEnd(digits, '0')}`;
}

export { numberWithCommas };
