import { Button } from 'react-bootstrap';

import { GOOGLE_ACCOUNT_CLIENT_ID } from '@config';

import { AccountLayout } from '../components';

import { getGoogleRedirectUri } from './utils';

export function SignIn() {
  const handleSignInButtonClick = () => {
    const redirectUri = getGoogleRedirectUri();

    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: GOOGLE_ACCOUNT_CLIENT_ID,
      scope: 'email profile',
      ux_mode: 'redirect',
      redirect_uri: redirectUri,
      state: window.location.host
    });

    client.requestCode();
  };

  return (
    <AccountLayout>
      <div className="mb-3 mb-0 text-center">
        <Button variant="primary" disabled={false} onClick={handleSignInButtonClick}>
          Log In
        </Button>
      </div>
    </AccountLayout>
  );
}
