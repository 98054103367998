import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { ApiRepository } from '@repositories/api';
import api from '@services/api';

import type {
  ReissueTwoFactorAccessTokenResponseError,
  ReissueTwoFactorAccessTokenResponseOK
} from '@repositories/api/auth.types';

function useExtendSignInMutation(): UseMutationResult<
  ReissueTwoFactorAccessTokenResponseOK,
  ReissueTwoFactorAccessTokenResponseError
> {
  return useMutation({
    mutationFn: ApiRepository.auth.reissueTwoFactorAccessToken,
    onSuccess(data) {
      if ('data' in data.data) {
        api.applyCredential({ token: data.data.data.accessToken });
      }
    }
  });
}

export { useExtendSignInMutation };
