import { getDefaultStore } from 'jotai';

import { atoms, initialState } from '../atoms';

type KeyofInitialState = keyof typeof initialState;
type KeyofAtoms = keyof typeof atoms;
type ValueofAtoms = (typeof atoms)[KeyofAtoms];
type Foo = keyof ValueofAtoms;

function resetAtoms() {
  const defaultStore = getDefaultStore();

  const initialStateStores = Object.keys(initialState) as KeyofInitialState[];

  (Object.keys(atoms) as (keyof typeof atoms)[]).forEach((store, index) => {
    (Object.keys(atoms[store]) as Foo[]).forEach((key) => {
      defaultStore.set(atoms[store][key], initialState[initialStateStores[index]][key]);
    });
  });
}

export { resetAtoms };
