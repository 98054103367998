import Chart from 'react-apexcharts';

import type { ApexOptions } from 'apexcharts';

export type BarChartDatum = {
  name?: string;
  data: {
    x: string;
    y: string | number;
  }[];
};

type Props = {
  data: BarChartDatum[];
  height?: string;
};

export function BarChart({ data, height }: Props) {
  const apexBarChartOpts: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom'
        }
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: true,
      offsetX: 10,
      formatter: (value) => `${value}%`
    },
    stroke: {
      width: 2,
      colors: ['#transparent']
    },
    xaxis: {
      min: 0,
      max: 100
    },
    yaxis: {
      labels: {
        show: true
      }
    },
    tooltip: {
      intersect: false,
      x: {
        show: false
      },
      y: {
        formatter: (value, option) =>
          `${option.w.globals.labels[option.dataPointIndex]} : ${value}%`,
        title: {
          formatter: () => ''
        }
      }
    }
  };

  return (
    <Chart
      height={height}
      options={apexBarChartOpts}
      series={data}
      type="bar"
      className="apex-charts"
    />
  );
}
