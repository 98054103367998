export const PATHNAMES = {
  // sign-in
  SIGN_IN: '/sign-in',

  // oauth
  OAUTH: '/oauth',
  VERIFY: 'verify',

  // dashboards
  DASHBOARDS: '/dashboards',

  // dashboards/revenue-flow-estimation
  REVENUE_FLOW_ESTIMATION: 'revenue-flow-estimation',

  // dashboards/statisctic
  STATISTICS: 'statistics',
  CHANNEL_COUNTS: 'channel-counts',
  SELLER_COUNTS: 'seller-counts',
  DAU: 'daily-active-users',
  MAU: 'monthly-active-users',
  DAILY_SELLER_CHANNEL_ACCESS: 'daily-seller-channel-access',
  P_APP_ADOPTION_RATE: 'p-app-adoption-rate'
} as const;
