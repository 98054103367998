export const endpoints = {
  HUB: {
    SERVICE_NAME: {
      GET_NUMBER: {
        method: 'GET',
        path: '/seller/api/v1/users',
        permission: 'public'
      },
      POST_NUMBER: {
        method: 'POST',
        path: '/seller/api/v1/me',
        permission: 'private'
      }
    },
    oauth: {
      GET_TWO_FACTOR_ACCESS_TOKEN: {
        method: 'POST',
        path: '/api/v1/oauth/two-factor-access-token',
        permission: 'private'
      },
      UPDATE_TWO_FACTOR_ACCESS_TOKEN: {
        method: 'POST',
        path: '/api/v1/oauth/two-factor-access-token/refresh',
        permission: 'private'
      },
      VALIDATE_TWO_FACTOR_ACCESS_TOKEN: {
        method: 'GET',
        path: '/api/v1/oauth/two-factor-access-token/validate',
        permission: 'private'
      }
    },
    dashboard: {
      FIND_PUBL_DAILY_TOTAL_SALES: {
        method: 'GET',
        path: '/api/v1/dashboard/publ/daily/total-sales',
        permission: 'private'
      },
      FIND_PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES: {
        method: 'GET',
        path: '/api/v1/dashboard/publ/daily/service-charge-estimated-sales',
        permission: 'private'
      },
      FIND_PUBL_DAILY_ESTIMATED_TOTAL_SALES_TOP: {
        method: 'GET',
        path: '/api/v1/dashboard/publ/daily/estimated-total-sales-top',
        permission: 'private'
      },
      FIND_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS: {
        method: 'GET',
        path: '/api/v1/dashboard/channels/daily/total-transaction-amount-details',
        permission: 'private'
      },
      FIND_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_PRESUMPTION: {
        method: 'GET',
        path: '/api/v1/dashboard/channels/daily/total-transaction-amount-presumption',
        permission: 'private'
      },
      FIND_CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT: {
        method: 'GET',
        path: '/api/v1/dashboard/channels/daily/cp-consumption-amount',
        permission: 'private'
      },
      FIND_CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT: {
        method: 'GET',
        path: '/api/v1/dashboard/channels/daily/total-expected-profit',
        permission: 'private'
      },
      FIND_PUBL_MONTHLY_TOTAL_SALES: {
        method: 'GET',
        path: '/api/v1/dashboard/publ/monthly/total-sales',
        permission: 'private'
      },
      FIND_PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES: {
        method: 'GET',
        path: '/api/v1/dashboard/publ/monthly/service-charge-estimated-sales',
        permission: 'private'
      },
      FIND_PUBL_MONTHLY_ESTIMATED_TOTAL_SALES_TOP: {
        method: 'GET',
        path: '/api/v1/dashboard/publ/monthly/estimated-total-sales-top',
        permission: 'private'
      },
      FIND_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS: {
        method: 'GET',
        path: '/api/v1/dashboard/channels/monthly/total-transaction-amount-details',
        permission: 'private'
      },
      FIND_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_PRESUMPTION: {
        method: 'GET',
        path: '/api/v1/dashboard/channels/monthly/total-transaction-amount-presumption',
        permission: 'private'
      },
      FIND_CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT: {
        method: 'GET',
        path: '/api/v1/dashboard/channels/monthly/cp-consumption-amount',
        permission: 'private'
      },
      FIND_CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT: {
        method: 'GET',
        path: '/api/v1/dashboard/channels/monthly/total-expected-profit',
        permission: 'private'
      }
    },
    statistics: {
      FIND_ALL_CHANNEL_COUNT: {
        method: 'GET',
        path: '/api/v1/statistics/channels/count',
        permission: 'private'
      },
      FIND_ALL_CHANNEL_ACCESS: {
        method: 'GET',
        path: '/api/v1/statistics/channels/access',
        permission: 'private'
      },
      FIND_ALL_SELLER_COUNT: {
        method: 'GET',
        path: '/api/v1/statistics/sellers/count',
        permission: 'private'
      },
      FIND_ALL_DAILY_ACCESS_USERS: {
        method: 'GET',
        path: '/api/v1/statistics/dau',
        permission: 'private'
      },
      FIND_ALL_MONTHLY_ACCSEE_USERS: {
        method: 'GET',
        path: '/api/v1/statistics/mau',
        permission: 'private'
      },
      FIND_ALL_P_APP_ADOPTION_RATE: {
        method: 'GET',
        path: '/api/v1/statistics/p-app-adoption-rate',
        permission: 'private'
      }
    }
  }
} as const;
