import { useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { dashboardsPath } from '@appConstants';
import { useRedux } from '@hooks';
import { authAtom } from '@store/atoms/auth';
import { changeBodyAttribute } from '@utils';

const loading = () => <div className="" />;

function DefaultLayout() {
  const isAuthenticated = useAtomValue(authAtom.isAuthenticated);

  const { appSelector } = useRedux();

  const { layoutColor } = appSelector((state) => ({
    layoutColor: state.Layout.layoutColor
  }));

  useEffect(() => {
    changeBodyAttribute('data-layout-color', layoutColor);
  }, [layoutColor]);

  if (isAuthenticated) {
    return <Navigate replace to={dashboardsPath} />;
  }

  return (
    <Suspense fallback={loading()}>
      <Outlet />
    </Suspense>
  );
}

export default DefaultLayout;
