import { useAtomValue } from 'jotai';
import { Navigate } from 'react-router-dom';

import { authAtom } from '@store/atoms/auth';

type PrivateRouteProps = {
  component: React.ComponentType;
};

function PrivateRoute({ component: RouteComponent }: PrivateRouteProps) {
  const isAuthenticated = useAtomValue(authAtom.isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate replace to="/" />;
  }

  return <RouteComponent />;
}

export default PrivateRoute;
