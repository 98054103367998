import { Card, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { sellerCountsPath } from '@appConstants';
import { Error, Loading, PageSize, Table } from '@components';
import { useGetSellerCountListQuery } from '@query/statistics/hooks';
import { CSVHelper } from '@utils/csvHelper';
import { generateUrl } from '@utils/generateUrl';
import { numberWithCommas } from '@utils/numberWithCommas';

import { SearchBar } from './SearchBar';

import type { SellerCount } from '@repositories/api/statistics.types';
import type { Column } from 'react-table';

type Props = {
  startAt: string;
  endAt: string;
  page: number;
  pageSize: number;
  pageSizeList: PageSize[];
};

const columns: ReadonlyArray<Column> = [
  {
    Header: () => <div className="text-center">일자</div>,
    accessor: 'date',
    Cell: ({ value }) => <div className="text-center">{value}</div>
  },
  {
    Header: <div className="text-center">신규 셀러 수</div>,
    accessor: 'newSellerCount',
    Cell: ({ value }) => <div className="text-center">{numberWithCommas(value)}</div>
  },
  {
    Header: <div className="text-center">누적 셀러 수</div>,
    accessor: 'cumulativeSellerCount',
    Cell: ({ value }) => <div className="text-center">{numberWithCommas(value)}</div>
  }
];

export function SellerCountsCard({ startAt, endAt, page, pageSize, pageSizeList }: Props) {
  const { data, isLoading, isPreviousData, isError } = useGetSellerCountListQuery({
    queryParams: {
      startAt,
      endAt,
      page,
      limit: pageSize,
      orderBy: 'DESC'
    },
    options: {
      keepPreviousData: true
    }
  });

  const navigate = useNavigate();
  const totalPage = Math.ceil((data?.pagination.totalEntry || 1) / pageSize);

  const handleSearch = (data: { startAt: string; endAt: string }) => {
    const { startAt, endAt } = data;

    navigate(generateUrl(sellerCountsPath, {}, { startAt, endAt, page: 1, pageSize }));
  };

  const handleCSVDownloadButtonClick = (data: SellerCount[]) => () => {
    const csvHeaders = ['일자', '신규 셀러 수', '누적 셀러 수'];

    const csvData = data.map((sellerCount) => {
      return [
        sellerCount.date,
        numberWithCommas(sellerCount.newSellerCount),
        numberWithCommas(sellerCount.cumulativeSellerCount)
      ];
    });

    new CSVHelper().download(
      csvHeaders,
      csvData,
      `${startAt} ~ ${endAt} 셀러 수 통계 (${page}-${totalPage})`
    );
  };

  return (
    <Card>
      <Card.Body>
        <Row className="mb-2">
          <Col className="d-flex justify-content-end">
            <SearchBar startAt={startAt} endAt={endAt} onSearch={handleSearch} />
          </Col>
        </Row>

        {isError ? (
          <Error />
        ) : isLoading ? (
          <Loading />
        ) : (
          <>
            <Row className="mb-2">
              <Col className="d-flex justify-content-end">
                <Button
                  variant="light"
                  onClick={handleCSVDownloadButtonClick(data.sellerCountList)}
                >
                  CSV Download
                </Button>
              </Col>
            </Row>

            <Table<SellerCount>
              columns={columns}
              data={data.sellerCountList}
              isFetching={isPreviousData}
              paginationProps={{
                currentPage: page,
                totalPage,
                currentPageSize: pageSize,
                pageSizeList,
                changePageSize(pageSize) {
                  const { value: currnetPagesize } = pageSizeList.find(
                    ({ value }) => value === pageSize
                  ) as PageSize;

                  navigate(
                    generateUrl(
                      sellerCountsPath,
                      {},
                      { startAt, endAt, page, pageSize: currnetPagesize }
                    )
                  );
                },
                movePage(value) {
                  if (value <= 0 || value > totalPage) {
                    return;
                  }

                  navigate(
                    generateUrl(sellerCountsPath, {}, { startAt, endAt, page: value, pageSize })
                  );
                },
                movePrevPage() {
                  if (page <= 1) {
                    return;
                  }

                  navigate(
                    generateUrl(sellerCountsPath, {}, { startAt, endAt, page: page - 1, pageSize })
                  );
                },
                moveNextPage() {
                  if (page >= totalPage) {
                    return;
                  }

                  navigate(
                    generateUrl(sellerCountsPath, {}, { startAt, endAt, page: page + 1, pageSize })
                  );
                }
              }}
              theadClass="table-light"
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
}
