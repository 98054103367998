import { QueryKey as OriginQueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QUERY_KEY } from '@query/keys';
import { ApiRepository } from '@repositories/api';

import type {
  ChannelTotalExpectedProfitResponseError,
  ChannelTotalExpectedProfitResponseOK,
  GetChannelTotalExpectedProfitRequestPayload
} from '@repositories/api/dashboard.types';
import type { AxiosResponse } from 'axios';

type Props<Data extends AxiosResponse, ErrorResponse, QueryKey extends OriginQueryKey> = {
  queryParams: GetChannelTotalExpectedProfitRequestPayload['queryParams'];
  options?: Omit<
    UseQueryOptions<Data, ErrorResponse, Data['data']['data'], QueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  >;
};

function useGetChannelMonthlyTotalExpectedProfitQuery<
  Data extends ChannelTotalExpectedProfitResponseOK,
  ErrorResponse extends ChannelTotalExpectedProfitResponseError
>({
  queryParams,
  options
}: Props<
  Data,
  ErrorResponse,
  ReturnType<typeof QUERY_KEY.DASHBOARD.GET_CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT>
>) {
  return useQuery({
    queryKey: QUERY_KEY.DASHBOARD.GET_CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT({ queryParams }),
    queryFn: () => {
      return ApiRepository.dashboard.getChannelMonthlyTotalExpectedProfit({ queryParams });
    },
    select: (data) => data.data.data,
    ...options
  });
}

export { useGetChannelMonthlyTotalExpectedProfitQuery };
