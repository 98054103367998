import { Card, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { pAppAdoptionRatePath, P_APP_CODE_NAME } from '@appConstants';
import { Error, Loading, Table } from '@components';
import { useGetPAppAdoptionRateListQuery } from '@query/statistics/hooks';
import { CSVHelper } from '@utils/csvHelper';
import { generateUrl } from '@utils/generateUrl';

import { BarChart } from './BarChart';
import { SearchBar } from './SearchBar';
import { Tabs } from './Tabs';

import type { PAppAdoptionRate } from '@repositories/api/statistics.types';
import type { Column } from 'react-table';

type PAppAdoptionRateTableData = {
  pAppCode: (typeof P_APP_CODE_NAME)[keyof typeof P_APP_CODE_NAME];
  adoptionRate: string;
}[];

type Props = {
  date: string;
};

const columns: ReadonlyArray<Column> = [
  {
    Header: () => <div className="text-center">pApp 이름</div>,
    accessor: 'pAppCode',
    Cell: ({ value }) => <div className="text-center">{value}</div>
  },
  {
    Header: <div className="text-center">채택률</div>,
    accessor: 'adoptionRate',
    Cell: ({ value }) => <div className="text-center">{`${value}%`}</div>
  }
];

export function PAppAdoptionRateCard({ date }: Props) {
  const { data, isLoading, isPreviousData, isError } = useGetPAppAdoptionRateListQuery({
    queryParams: {
      startAt: date,
      endAt: date,
      orderBy: 'DESC',
      limit: 1,
      page: 1
    },
    options: {
      keepPreviousData: true
    }
  });

  const navigate = useNavigate();

  const handleSearch = (data: { date: string }) => {
    const { date } = data;

    navigate(generateUrl(pAppAdoptionRatePath, {}, { date }));
  };

  const handleCSVDownloadButtonClick = (data: PAppAdoptionRate['pAppAdoptionRate']) => () => {
    const csvHeaders = ['일자', '접근 여부'];

    const csvData = data.map((pAppAdoptionRate) => {
      return [P_APP_CODE_NAME[pAppAdoptionRate.pAppCode], `${pAppAdoptionRate.adoptionRate}%`];
    });

    new CSVHelper().download(csvHeaders, csvData, `${date} pApp 채택률 통계`);
  };

  const generateTableData = (data: PAppAdoptionRate['pAppAdoptionRate']) => {
    const tableData = data
      .sort((a, b) => Number(b.adoptionRate) - Number(a.adoptionRate))
      .map(({ pAppCode, adoptionRate }) => ({
        pAppCode: P_APP_CODE_NAME[pAppCode],
        adoptionRate
      }));

    return tableData;
  };

  const generateChartData = (data: PAppAdoptionRate['pAppAdoptionRate']) => {
    const chartData = [
      {
        data: data
          .sort((a, b) => Number(b.adoptionRate) - Number(a.adoptionRate))
          .map(({ pAppCode, adoptionRate }) => ({
            x: P_APP_CODE_NAME[pAppCode],
            y: adoptionRate
          }))
      }
    ];

    return chartData;
  };

  const getHeight = (data: PAppAdoptionRate) => {
    return `${data.pAppAdoptionRate.length * 30}px`;
  };

  return (
    <Card>
      <Card.Body>
        <Row className="mb-1">
          <Col className="d-flex justify-content-end">
            <SearchBar date={date} onSearch={handleSearch} />
          </Col>
        </Row>

        {isError ? (
          <Error />
        ) : isLoading ? (
          <Loading />
        ) : (
          <>
            <Button
              className="mt-1 float-end"
              variant="light"
              onClick={handleCSVDownloadButtonClick(
                data.pAppAdoptionRateInfoList[0].pAppAdoptionRate
              )}
            >
              CSV Download
            </Button>

            <Tabs
              tabItems={[
                {
                  id: 'TABLE',
                  title: 'table',
                  content: (
                    <Table<PAppAdoptionRateTableData[number]>
                      columns={columns}
                      data={generateTableData(data.pAppAdoptionRateInfoList[0].pAppAdoptionRate)}
                      isFetching={isPreviousData}
                      theadClass="table-light"
                    />
                  )
                },
                {
                  id: 'CHART',
                  title: 'chart',
                  content: (
                    <BarChart
                      height={getHeight(data.pAppAdoptionRateInfoList[0])}
                      data={generateChartData(data.pAppAdoptionRateInfoList[0].pAppAdoptionRate)}
                    />
                  )
                }
              ]}
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
}
