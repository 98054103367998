import { Nav, Tab } from 'react-bootstrap';

type TabItem = {
  id: string;
  title: string;
  content: React.ReactNode;
  status?: 'normal' | 'disabled';
};

type Props = {
  title: string;
  defaultActivateTab?: string;
  contentsHeight: string;
  tabItems: TabItem[];
};

export function Tabs({ title, contentsHeight, defaultActivateTab, tabItems }: Props) {
  return (
    <Tab.Container defaultActiveKey={defaultActivateTab ?? tabItems[0].id}>
      <div className="align-items-center d-sm-flex justify-content-sm-between mb-2">
        <h4 className="header-title mb-0">{title}</h4>
        <Nav as="ul" variant="pills" className="bg-nav-pills p-1 rounded">
          {tabItems.map((tab) => {
            return (
              <Nav.Item key={tab.id} as="li">
                <Nav.Link
                  as="button"
                  className="py-1"
                  eventKey={tab.id}
                  disabled={tab.status === 'disabled'}
                >
                  {tab.title}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </div>

      <Tab.Content style={{ height: contentsHeight, maxHeight: contentsHeight, overflow: 'auto' }}>
        {tabItems.map((tab) => {
          return (
            <Tab.Pane style={{ height: '100%' }} eventKey={tab.id} key={tab.id}>
              {tab.content}
            </Tab.Pane>
          );
        })}
      </Tab.Content>
    </Tab.Container>
  );
}
