import api, { getEndpointSet } from '@services/api';

import type {
  GetChannelCpConsumptionAmountRequestPayload,
  GetChannelTotalExpectedProfitRequestPayload,
  GetChannelTotalTransactionAmountDetailsRequestPayload,
  GetChannelTotalTransactionAmountResumptionRequestPayload,
  GetPublEstimatedTotalSalesTopRequestPayload,
  GetPublServiceChargeEstimatedSalesRequestPayload,
  GetPublTotalSalesRequestPayload
} from './dashboard.types';

const {
  FIND_PUBL_DAILY_TOTAL_SALES,
  FIND_PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES,
  FIND_PUBL_DAILY_ESTIMATED_TOTAL_SALES_TOP,
  FIND_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
  FIND_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_PRESUMPTION,
  FIND_CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT,
  FIND_CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT,
  FIND_PUBL_MONTHLY_TOTAL_SALES,
  FIND_PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES,
  FIND_PUBL_MONTHLY_ESTIMATED_TOTAL_SALES_TOP,
  FIND_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
  FIND_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_PRESUMPTION,
  FIND_CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT,
  FIND_CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT
} = getEndpointSet('HUB', 'dashboard');

class DashboardRepository {
  static getPublDailyTotalSales({ queryParams }: GetPublTotalSalesRequestPayload) {
    return api.get(FIND_PUBL_DAILY_TOTAL_SALES, {}, queryParams);
  }

  static getPublDailyServiceChargeEstimatedSales({
    queryParams
  }: GetPublServiceChargeEstimatedSalesRequestPayload) {
    return api.get(FIND_PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES, {}, queryParams);
  }

  static getPublDailyEstimatedTotalSalesTop({
    queryParams
  }: GetPublEstimatedTotalSalesTopRequestPayload) {
    return api.get(FIND_PUBL_DAILY_ESTIMATED_TOTAL_SALES_TOP, {}, queryParams);
  }

  static getChannelDailyTotalTransactionAmountDetails({
    queryParams
  }: GetChannelTotalTransactionAmountDetailsRequestPayload) {
    return api.get(FIND_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS, {}, queryParams);
  }

  static getChannelDailyTotalTransactionAmountResumption({
    queryParams
  }: GetChannelTotalTransactionAmountResumptionRequestPayload) {
    return api.get(FIND_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_PRESUMPTION, {}, queryParams);
  }

  static getChannelDailyCpConsumptionAmount({
    queryParams
  }: GetChannelCpConsumptionAmountRequestPayload) {
    return api.get(FIND_CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT, {}, queryParams);
  }

  static getChannelDailyTotalExpectedProfit({
    queryParams
  }: GetChannelTotalExpectedProfitRequestPayload) {
    return api.get(FIND_CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT, {}, queryParams);
  }

  static getPublMonthlyTotalSales({ queryParams }: GetPublTotalSalesRequestPayload) {
    return api.get(FIND_PUBL_MONTHLY_TOTAL_SALES, {}, queryParams);
  }

  static getPublMonthlyServiceChargeEstimatedSales({
    queryParams
  }: GetPublServiceChargeEstimatedSalesRequestPayload) {
    return api.get(FIND_PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES, {}, queryParams);
  }

  static getPublMonthlyEstimatedTotalSalesTop({
    queryParams
  }: GetPublEstimatedTotalSalesTopRequestPayload) {
    return api.get(FIND_PUBL_MONTHLY_ESTIMATED_TOTAL_SALES_TOP, {}, queryParams);
  }

  static getChannelMonthlyTotalTransactionAmountDetails({
    queryParams
  }: GetChannelTotalTransactionAmountDetailsRequestPayload) {
    return api.get(FIND_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS, {}, queryParams);
  }

  static getChannelMonthlyTotalTransactionAmountResumption({
    queryParams
  }: GetChannelTotalTransactionAmountResumptionRequestPayload) {
    return api.get(FIND_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_PRESUMPTION, {}, queryParams);
  }

  static getChannelMonthlyCpConsumptionAmount({
    queryParams
  }: GetChannelCpConsumptionAmountRequestPayload) {
    return api.get(FIND_CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT, {}, queryParams);
  }

  static getChannelMonthlyTotalExpectedProfit({
    queryParams
  }: GetChannelTotalExpectedProfitRequestPayload) {
    return api.get(FIND_CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT, {}, queryParams);
  }
}

export default DashboardRepository;
