import { useLocation } from 'react-router-dom';

import { DateHelper } from '@utils/DateHelper';

import type { PageSize } from '@components';

type Props = {
  pageSizeList: PageSize[];
};

export function useQueryParams({ pageSizeList }: Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const channelId = searchParams.get('channelId');
  const startAt = searchParams.get('startAt');
  const endAt = searchParams.get('endAt');
  const page = Number(searchParams.get('page'));
  const pageSize = Number(searchParams.get('pageSize'));

  const isValidStartAt = new DateHelper().validate(startAt);
  const isValidEndAt = new DateHelper().validate(endAt);
  const isValidPage = page === 0 || Number.isInteger(page);
  const isValidPageSize =
    pageSize === 0 ||
    (Number.isInteger(pageSize) && pageSizeList.find(({ value }) => value === pageSize));

  const isInValidQueryParams = !isValidStartAt || !isValidEndAt || !isValidPage || !isValidPageSize;

  if (isInValidQueryParams) {
    return {
      channelId,
      startAt,
      endAt,
      page,
      pageSize,
      pageSizeList,
      isInValidQueryParams
    };
  }

  return {
    channelId,
    startAt,
    endAt,
    page,
    pageSize,
    pageSizeList,
    isInValidQueryParams
  };
}
