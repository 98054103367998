import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { LOGO_HUB } from '@appConstants';

import { useAccountLayout } from '../hooks';

import type React from 'react';

type AccountLayoutProps = {
  bottomLinks?: React.ReactNode;
  children?: React.ReactNode;
};

export function AccountLayout({ bottomLinks, children }: AccountLayoutProps) {
  useAccountLayout();

  return (
    <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5} xxl={4}>
            <Card>
              <Card.Header className="pt-4 pb-4 text-center bg-primary">
                <Link to="/">
                  <span>
                    <img src={LOGO_HUB} alt="" height="80" />
                  </span>
                </Link>
              </Card.Header>
              <Card.Body className="p-4">{children}</Card.Body>
            </Card>

            {bottomLinks}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
