import { LayoutTypes, LayoutColor, LayoutWidth, SideBarTheme, SideBarType } from '@appConstants';
import { getLayoutConfigs } from '@utils';

import { LayoutActionTypes, LayoutStateType } from './constants';

import type { LayoutActionType } from './actions';

type LayoutKey =
  | 'layoutColor'
  | 'layoutType'
  | 'layoutWidth'
  | 'leftSideBarTheme'
  | 'leftSideBarType';
type DefaultValue = LayoutTypes | LayoutColor | LayoutWidth | SideBarTheme | SideBarType;

function setLayoutConfigToLocalStorage<T extends DefaultValue>(key: LayoutKey, value: T) {
  localStorage.setItem(key, value);
}

function getLayoutConfigInLocalStorage<T extends DefaultValue>(key: LayoutKey, defaultValue: T) {
  const item = localStorage.getItem(key) as T | null;

  if (item === null) {
    localStorage.setItem(key, defaultValue);

    return defaultValue;
  }

  return item;
}

const INIT_STATE: LayoutStateType = {
  layoutColor: getLayoutConfigInLocalStorage('layoutColor', LayoutColor.LAYOUT_COLOR_LIGHT),
  layoutType: getLayoutConfigInLocalStorage('layoutType', LayoutTypes.LAYOUT_VERTICAL),
  layoutWidth: getLayoutConfigInLocalStorage('layoutWidth', LayoutWidth.LAYOUT_WIDTH_FLUID),
  leftSideBarTheme: getLayoutConfigInLocalStorage(
    'leftSideBarTheme',
    SideBarTheme.LEFT_SIDEBAR_THEME_DARK
  ),
  leftSideBarType: getLayoutConfigInLocalStorage(
    'leftSideBarType',
    SideBarType.LEFT_SIDEBAR_TYPE_FIXED
  ),
  showRightSidebar: false
};

const Layout = (
  // eslint-disable-next-line default-param-last
  state: LayoutStateType = INIT_STATE,
  action: LayoutActionType<string | boolean | null>
) => {
  switch (action.type) {
    case LayoutActionTypes.CHANGE_LAYOUT_COLOR:
      setLayoutConfigToLocalStorage('layoutColor', action.payload as LayoutColor);
      return {
        ...state,
        layoutColor: action.payload
      };
    case LayoutActionTypes.CHANGE_LAYOUT:
      setLayoutConfigToLocalStorage('layoutType', action.payload as LayoutTypes);
      return {
        ...state,
        layoutType: action.payload
      };
    case LayoutActionTypes.CHANGE_LAYOUT_WIDTH:
      setLayoutConfigToLocalStorage('layoutWidth', action.payload as LayoutWidth);
      return {
        ...state,
        layoutWidth: action.payload,
        ...getLayoutConfigs(action.type, action.payload!)
      };
    case LayoutActionTypes.CHANGE_SIDEBAR_THEME:
      setLayoutConfigToLocalStorage('leftSideBarTheme', action.payload as SideBarTheme);
      return {
        ...state,
        leftSideBarTheme: action.payload
      };
    case LayoutActionTypes.CHANGE_SIDEBAR_TYPE:
      setLayoutConfigToLocalStorage('leftSideBarType', action.payload as SideBarType);
      return {
        ...state,
        leftSideBarType: action.payload
      };
    case LayoutActionTypes.SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true
      };
    case LayoutActionTypes.HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false
      };
    default:
      return state;
  }
};

export default Layout;
