import { useEffect } from 'react';
import { Card } from 'react-bootstrap';

import { Error, Loading } from '@components';
import { useGetChannelDailyTotalTransactionAmountDetailsQuery } from '@query/dashboard/hooks';
import { numberWithCommas } from '@utils';

import { applyExchageRate, generateBarChartData } from '../utils';

import { BarChart } from './BarChart';
import { DataTable } from './DataTable';
import { Tabs } from './Tabs';

type Props = {
  startAt: string;
  endAt: string;
  exchangeRate: string;
  isEnabled: boolean;

  onFetchComplete: () => void;
};

export function ChannelDailyTotalTransactionAmountDetails({
  startAt,
  endAt,
  exchangeRate,
  isEnabled,
  onFetchComplete
}: Props) {
  const { data, isLoading, isFetching, isError, refetch } =
    useGetChannelDailyTotalTransactionAmountDetailsQuery({
      queryParams: {
        startAt,
        endAt,
        orderBy: 'ASC'
      },
      options: {
        enabled: isEnabled,
        onSettled: onFetchComplete
      }
    });

  useEffect(() => {
    if (isEnabled) {
      refetch();
    }
  }, [isEnabled]);

  const makePreprocessedData = (
    responseData: NonNullable<typeof data>['totalTransactionAmountDetails']
  ) => {
    return responseData.map(
      ({
        date,
        SUBSCRIPTION_PRODUCT_PURCHASE_KRW,
        SUBSCRIPTION_PRODUCT_PURCHASE_USD,
        P_APP_UNLOCK_PRODUCT_PURCHASE_KRW,
        P_APP_UNLOCK_PRODUCT_PURCHASE_USD,
        CHANNEL_POINT_CHARGE_PAYMENT_KRW,
        CHANNEL_POINT_CHARGE_PAYMENT_USD
      }) => ({
        date,
        SUBSCRIPTION_PRODUCT_PURCHASE: String(
          Number(SUBSCRIPTION_PRODUCT_PURCHASE_KRW) +
            applyExchageRate(SUBSCRIPTION_PRODUCT_PURCHASE_USD, exchangeRate)
        ),
        P_APP_UNLOCK_PRODUCT_PURCHASE: String(
          Number(P_APP_UNLOCK_PRODUCT_PURCHASE_KRW) +
            applyExchageRate(P_APP_UNLOCK_PRODUCT_PURCHASE_USD, exchangeRate)
        ),
        CHANNEL_POINT_CHARGE_PAYMENT: String(
          Number(CHANNEL_POINT_CHARGE_PAYMENT_KRW) +
            applyExchageRate(CHANNEL_POINT_CHARGE_PAYMENT_USD, exchangeRate)
        )
      })
    );
  };

  return (
    <Card>
      <Card.Body>
        <Tabs
          title="일별 채널 거래액 : 채널의 총 거래액 상세"
          contentsHeight="400px"
          tabItems={[
            {
              id: 'BAR_CHART',
              title: 'Bar Chart',
              content: (
                <>
                  {isError ? (
                    <Error />
                  ) : isLoading || isFetching ? (
                    <Loading />
                  ) : (
                    <BarChart
                      data={generateBarChartData({
                        type: 'bar',
                        keys: [
                          'SUBSCRIPTION_PRODUCT_PURCHASE',
                          'P_APP_UNLOCK_PRODUCT_PURCHASE',
                          'CHANNEL_POINT_CHARGE_PAYMENT'
                        ],
                        labels: {
                          SUBSCRIPTION_PRODUCT_PURCHASE: '구독상품 결제액 (B+C)',
                          P_APP_UNLOCK_PRODUCT_PURCHASE: '유료이용권 결제액 (D+E)',
                          CHANNEL_POINT_CHARGE_PAYMENT: 'CP 충전 결제액 (F+G)'
                        },
                        data: makePreprocessedData(data.totalTransactionAmountDetails)
                      })}
                      formatYAxis={(value) => {
                        const checkedValue = isFinite(value) ? value : 0;

                        return numberWithCommas(checkedValue);
                      }}
                    />
                  )}
                </>
              )
            },
            {
              id: 'DATA_TABLE',
              title: 'Data Table',
              content: (
                <>
                  {isError ? (
                    <Error />
                  ) : isLoading || isFetching ? (
                    <Loading />
                  ) : (
                    <DataTable
                      data={[...data.totalTransactionAmountDetails].reverse()}
                      columns={[
                        { header: '날짜', field: 'date' },
                        {
                          header: '구독상품 결제액KRW (B)',
                          field: 'SUBSCRIPTION_PRODUCT_PURCHASE_KRW',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '구독상품 결제액USD (C)',
                          field: 'SUBSCRIPTION_PRODUCT_PURCHASE_USD',
                          formatFiled: (value) => numberWithCommas(value, 2)
                        },
                        {
                          header: '유료이용권 결제액KRW (D)',
                          field: 'P_APP_UNLOCK_PRODUCT_PURCHASE_KRW',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '유료이용권 결제액USD (E)',
                          field: 'P_APP_UNLOCK_PRODUCT_PURCHASE_USD',
                          formatFiled: (value) => numberWithCommas(value, 2)
                        },
                        {
                          header: 'CP 충전 결제액KRW (F)',
                          field: 'CHANNEL_POINT_CHARGE_PAYMENT_KRW',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: 'CP 충전 결제액USD (G)',
                          field: 'CHANNEL_POINT_CHARGE_PAYMENT_USD',
                          formatFiled: (value) => numberWithCommas(value, 2)
                        },
                        {
                          header: '총 결제액KRW (H = B+D+F)',
                          field: 'TOTAL_PAYMENT_AMOUNT_KRW',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '총 결제액USD (I = C+E+G)',
                          field: 'TOTAL_PAYMENT_AMOUNT_USD',
                          formatFiled: (value) => numberWithCommas(value, 2)
                        }
                      ]}
                    />
                  )}
                </>
              )
            }
          ]}
        />
      </Card.Body>
    </Card>
  );
}
