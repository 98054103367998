import { QueryKey as OriginQueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QUERY_KEY } from '@query/keys';
import { ApiRepository } from '@repositories/api';

import type {
  GetChannelCountListRequestPayload,
  ChannelCountListResponseOK,
  ChannelCountListResponseError
} from '@repositories/api/statistics.types';
import type { AxiosResponse } from 'axios';

type Props<Data extends AxiosResponse, ErrorResponse, QueryKey extends OriginQueryKey> = {
  queryParams: GetChannelCountListRequestPayload['queryParams'];
  options?: Omit<
    UseQueryOptions<Data, ErrorResponse, Data['data']['data'], QueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  >;
};

function useGetChannelCountListQuery<
  Data extends ChannelCountListResponseOK,
  ErrorResponse extends ChannelCountListResponseError
>({
  queryParams,
  options
}: Props<Data, ErrorResponse, ReturnType<typeof QUERY_KEY.STATISTICS.GET_CHANNEL_COUNT_LIST>>) {
  return useQuery({
    queryKey: QUERY_KEY.STATISTICS.GET_CHANNEL_COUNT_LIST({ queryParams }),
    queryFn: () => {
      return ApiRepository.statistics.getChannelCountList({ queryParams });
    },
    select: (data) => data.data.data,
    ...options
  });
}

export { useGetChannelCountListQuery };
