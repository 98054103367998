import { useQueryClient } from '@tanstack/react-query';

import api from '@services/api';
import { resetAtoms } from '@store/utils';

export function useSignOut() {
  const queryClient = useQueryClient();

  const signOut = () => {
    resetAtoms();
    api.purgeCredential();
    queryClient.clear();
  };

  return { signOut };
}
