import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { dashboardsPath } from '@appConstants';
import { VerticalForm, FormInput } from '@components';
import { useSignInMutation } from '@query/auth/hooks/useSignInMutation';
import { authAtom } from '@store/atoms/auth';

import { AccountLayout } from '../../components';

type UserData = {
  password: string;
};

export function Verify() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const accessToken = search.get('accessToken');

  const setIsAuthenticated = useSetAtom(authAtom.isAuthenticated);

  const { mutate, isSuccess, isError, error } = useSignInMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsAuthenticated(true);

      navigate(dashboardsPath);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      // NOTE: Custom Error, 1차 access token이 만료된 경우 발생합니다.
      if ('msg' in error.data) {
        navigate('/');

        return;
      }
    }
  }, [isError, error]);

  if (!accessToken) {
    return <Navigate to="/" />;
  }

  const onSubmit = ({ password }: UserData) => {
    const variables = {
      header: {
        accessToken
      },
      body: { twoFactorPassword: password }
    };

    mutate(variables);
  };

  return (
    <AccountLayout>
      <div className="text-center w-75 m-auto">
        <h4 className="text-dark-50 text-center mb-3 fw-bold">2차 비밀번호 입력</h4>
      </div>

      {error && (
        <Alert variant="danger" className="my-2">
          인증 정보가 정확하지 않습니다. 다시 시도해 주세요.
        </Alert>
      )}

      <VerticalForm<UserData> onSubmit={onSubmit}>
        <FormInput
          label="Password"
          type="password"
          name="password"
          placeholder="Enter your password"
          containerClass="mb-3"
        />

        <div className="mb-0 text-center">
          <Button variant="primary" type="submit">
            Log In
          </Button>
        </div>
      </VerticalForm>
    </AccountLayout>
  );
}
