import { useEffect } from 'react';
import { Card } from 'react-bootstrap';

import { Error, Loading } from '@components';
import { useGetChannelDailyTotalTransactionAmountResumptionQuery } from '@query/dashboard/hooks';
import { numberWithCommas } from '@utils';

import { generateBarChartData } from '../utils';

import { BarChart } from './BarChart';
import { DataTable } from './DataTable';
import { Tabs } from './Tabs';

type Props = {
  startAt: string;
  endAt: string;
  exchangeRate: string;
  isEnabled: boolean;
  onFetchComplete: () => void;
};

export function ChannelDailyTotalTransactionAmountResumption({
  startAt,
  endAt,
  exchangeRate,
  isEnabled,
  onFetchComplete
}: Props) {
  const { data, isLoading, isFetching, isError, refetch } =
    useGetChannelDailyTotalTransactionAmountResumptionQuery({
      queryParams: {
        startAt,
        endAt,
        exchangeRate,
        orderBy: 'ASC'
      },
      options: {
        enabled: isEnabled,
        onSettled: onFetchComplete
      }
    });

  useEffect(() => {
    if (isEnabled) {
      refetch();
    }
  }, [isEnabled]);

  return (
    <Card>
      <Card.Body>
        <Tabs
          title="일별 채널 거래액 : 채널의 총 거래액 추정"
          contentsHeight="400px"
          tabItems={[
            {
              id: 'BAR_CHART',
              title: 'Bar Chart',
              content: (
                <>
                  {isError ? (
                    <Error />
                  ) : isLoading || isFetching ? (
                    <Loading />
                  ) : (
                    <BarChart
                      data={generateBarChartData({
                        type: 'bar',
                        keys: [
                          'SUBSCRIPTION_PRODUCT_PURCHASE_PRESUMPTION',
                          'P_APP_UNLOCK_PRODUCT_PURCHASE_PRESUMPTION',
                          'CHANNEL_POINT_CHARGE_PAYMENT_PRESUMPTION'
                        ],
                        labels: {
                          SUBSCRIPTION_PRODUCT_PURCHASE_PRESUMPTION: '구독상품 추정 결제액 (B)',
                          P_APP_UNLOCK_PRODUCT_PURCHASE_PRESUMPTION: '유료이용권 추정 결제액 (C)',
                          CHANNEL_POINT_CHARGE_PAYMENT_PRESUMPTION: 'CP 충전 추정 결제액 (D)'
                        },
                        data: data.totalTransactionAmountPresumptionList
                      })}
                      formatYAxis={(value) => {
                        const checkedValue = isFinite(value) ? value : 0;

                        return numberWithCommas(checkedValue);
                      }}
                    />
                  )}
                </>
              )
            },
            {
              id: 'DATA_TABLE',
              title: 'Data Table',
              content: (
                <>
                  {isError ? (
                    <Error />
                  ) : isLoading || isFetching ? (
                    <Loading />
                  ) : (
                    <DataTable
                      data={[...data.totalTransactionAmountPresumptionList].reverse()}
                      columns={[
                        { header: '날짜', field: 'date' },
                        {
                          header: '구독상품 추정 결제액 (B)',
                          field: 'SUBSCRIPTION_PRODUCT_PURCHASE_PRESUMPTION',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '유료이용권 추정 결제액 (C)',
                          field: 'P_APP_UNLOCK_PRODUCT_PURCHASE_PRESUMPTION',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: 'CP 충전 추정 결제액 (D)',
                          field: 'CHANNEL_POINT_CHARGE_PAYMENT_PRESUMPTION',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: '추정 총 결제액 (B+C+D)',
                          field: 'TOTAL_PAYMENT_AMOUNT_PRESUMPTION',
                          formatFiled: (value) => numberWithCommas(value)
                        }
                      ]}
                    />
                  )}
                </>
              )
            }
          ]}
        />
      </Card.Body>
    </Card>
  );
}
