import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QUERY_KEY } from '@query/keys';
import { ApiRepository } from '@repositories/api';

import type {
  ValidateTwoFactorAccessTokenResponseError,
  ValidateTwoFactorAccessTokenResponseOK
} from '@repositories/api/auth.types';
import type { AxiosResponse } from 'axios';

type Props<Data extends AxiosResponse, ErrorResponse, KeyFn extends () => QueryKey> = {
  accessToken?: string | null;
  options?: Omit<
    UseQueryOptions<Data, ErrorResponse, Data['data'], ReturnType<KeyFn>>,
    'queryKey' | 'queryFn' | 'initialData'
  >;
};

function useAuthValidateQuery<
  Data extends ValidateTwoFactorAccessTokenResponseOK,
  ErrorResponse extends ValidateTwoFactorAccessTokenResponseError
>({ options }: Props<Data, ErrorResponse, typeof QUERY_KEY.AUTH.VALIDATE_ACCESS_TOKEN>) {
  return useQuery({
    queryKey: QUERY_KEY.AUTH.VALIDATE_ACCESS_TOKEN(),
    queryFn: ApiRepository.auth.validateTwoFactorAccessToken,
    select: (data) => data.data,
    ...options
  });
}

export { useAuthValidateQuery };
