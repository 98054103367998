import { Row, Col } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

import { dashboardsPath, monthlyActiveUsersPath, statisticsPath } from '@appConstants';
import { PageTitle } from '@components';
import { DateHelper } from '@utils/DateHelper';
import { generateUrl } from '@utils/generateUrl';

import { MAUCard } from './components';
import { useQueryParams } from './hooks';

const breadcrumbItems = [
  {
    label: 'Dashboards',
    path: dashboardsPath,
    active: false
  },
  {
    label: '통계',
    path: statisticsPath,
    active: false
  },
  {
    label: 'MAU',
    path: monthlyActiveUsersPath,
    active: true
  }
];

const PAGE_SIZE_LIST = [
  {
    text: '15',
    value: 15
  },
  {
    text: '30',
    value: 30
  },
  {
    text: '50',
    value: 50
  },
  {
    text: '100',
    value: 100
  }
];

export function MAU() {
  const { startAt, endAt, page, pageSize, pageSizeList, isInValidQueryParams } = useQueryParams({
    pageSizeList: PAGE_SIZE_LIST
  });

  if (isInValidQueryParams) {
    const lastMonthToday = new DateHelper().subtract(1, 'M').format('YYYY-MM');
    const today = new DateHelper().format('YYYY-MM');
    const defaultPage = 1;
    const defaultPageSize = 15;
    const defaultQueryParams = {
      startAt: lastMonthToday,
      endAt: today,
      page: defaultPage,
      pageSize: defaultPageSize
    };

    return <Navigate replace to={generateUrl(monthlyActiveUsersPath, {}, defaultQueryParams)} />;
  }

  return (
    <>
      <PageTitle title="MAU" breadCrumbItems={breadcrumbItems} />

      <Row>
        <Col xs={12}>
          <MAUCard
            startAt={startAt}
            endAt={endAt}
            page={page}
            pageSize={pageSize}
            pageSizeList={pageSizeList}
          />
        </Col>
      </Row>
    </>
  );
}
