import axios, { AxiosRequestConfig } from 'axios';

import RESPONSES from './responses.json';

const { RESP_AXIOS_NETWORK_CONNECT_TIMEOUT_ERROR, RESP_AXIOS_UNDEFINED_ERROR } = RESPONSES;

export type Call = {
  method: AxiosRequestConfig['method'];
  url: AxiosRequestConfig['url'];
  params?: { [key: string]: string | number | boolean };
  body?: Record<string, unknown>;
  options?: Omit<AxiosRequestConfig, 'method' | 'url' | 'params' | 'data'>;
};

class Request {
  axios: typeof axios;

  constructor() {
    this.axios = axios;
  }

  getInstance() {
    return this.axios;
  }

  call({ method, url, params, body, options }: Call) {
    const config = {
      method,
      url,
      params,
      ...options,
      ...(body != null && { data: body })
    };

    if (body instanceof FormData) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      };
    }

    return this.axiosWithErrorHandler(config);
  }

  async axiosWithErrorHandler(config: any) {
    try {
      const response = await this.axios({ ...config, timeout: 180_000 });

      return response;
    } catch (error) {
      return this.generateResponseFromError(error);
    }
  }

  generateResponseFromError(error: any) {
    // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답한 경우
    if (error.response) {
      throw error.response;
    }

    // 요청이 이루어 졌으나 응답을 받지 못 한 경우
    if (error.request) {
      throw RESP_AXIOS_NETWORK_CONNECT_TIMEOUT_ERROR;
    }

    // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생한 경우
    throw RESP_AXIOS_UNDEFINED_ERROR;
  }
}

export default Request;
