import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { HyperDatepicker } from '@components';
import { DateHelper } from '@utils/DateHelper';

type FormValues = {
  startAt: Date;
  endAt: Date;
};

type Props = {
  startAt: string;
  endAt: string;
  onSearch?: (data: { startAt: string; endAt: string }) => void;
};

export function SearchBar({ startAt, endAt, onSearch }: Props) {
  const methods = useForm<FormValues>({
    defaultValues: {
      startAt: startAt ? new Date(startAt) : new Date(),
      endAt: endAt ? new Date(endAt) : new Date()
    }
  });
  const { handleSubmit, control } = methods;

  const onSubmit = (data: FormValues) => {
    if (onSearch) {
      onSearch({
        startAt: new DateHelper(data.startAt).format('YYYY-MM-DD'),
        endAt: new DateHelper(data.endAt).format('YYYY-MM-DD')
      });
    }
  };

  return (
    <Form className="d-flex align-items-end" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group style={{ width: 150 }}>
        <Form.Label>Start Date</Form.Label>
        <HyperDatepicker name="startAt" control={control} inputClass="form-control" />
      </Form.Group>
      <Form.Group className="ms-2" style={{ width: 150 }}>
        <Form.Label>End Date</Form.Label>
        <HyperDatepicker name="endAt" control={control} inputClass="form-control" />
      </Form.Group>
      <div className="ms-2">
        <Button type="submit">Submit</Button>
      </div>
    </Form>
  );
}
