import { authAtom, initialState as authInitialState } from './auth';

const atoms = {
  authAtom
};

const initialState = {
  authInitialState
};

export { atoms, initialState };
