import { Card, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { channelCountsPath } from '@appConstants';
import { Loading, PageSize, Table, Error } from '@components';
import { useGetChannelCountListQuery } from '@query/statistics/hooks';
import { CSVHelper } from '@utils/csvHelper';
import { generateUrl } from '@utils/generateUrl';
import { numberWithCommas } from '@utils/numberWithCommas';

import { SearchBar } from './SearchBar';

import type { ChannelCount } from '@repositories/api/statistics.types';
import type { Column } from 'react-table';

type Props = {
  startAt: string;
  endAt: string;
  page: number;
  pageSize: number;
  pageSizeList: PageSize[];
};

const columns: ReadonlyArray<Column> = [
  {
    Header: () => <div className="text-center">일자</div>,
    accessor: 'date',
    Cell: ({ value }) => <div className="text-center">{value}</div>
  },
  {
    Header: <div className="text-center">신규 채널 수</div>,
    accessor: 'newChannelCount',
    Cell: ({ value }) => <div className="text-center">{numberWithCommas(value)}</div>
  },
  {
    Header: <div className="text-center">누적 채널 수</div>,
    accessor: 'cumulativeChannelCount',
    Cell: ({ value }) => <div className="text-center">{numberWithCommas(value)}</div>
  },
  {
    Header: <div className="text-center">내부 신규 채널 수</div>,
    accessor: 'internalNewChannelCount',
    Cell: ({ value }) => <div className="text-center">{numberWithCommas(value)}</div>
  },
  {
    Header: <div className="text-center">내부 누적 채널 수</div>,
    accessor: 'internalCumulativeChannelCount',
    Cell: ({ value }) => <div className="text-center">{numberWithCommas(value)}</div>
  }
];

export function ChannelCountsCard({ startAt, endAt, page, pageSize, pageSizeList }: Props) {
  const { data, isLoading, isPreviousData, isError } = useGetChannelCountListQuery({
    queryParams: {
      startAt,
      endAt,
      page,
      limit: pageSize,
      orderBy: 'DESC'
    },
    options: {
      keepPreviousData: true
    }
  });

  const navigate = useNavigate();
  const totalPage = Math.ceil((data?.pagination.totalEntry || 1) / pageSize);

  const handleSearch = (data: { startAt: string; endAt: string }) => {
    const { startAt, endAt } = data;

    navigate(generateUrl(channelCountsPath, {}, { startAt, endAt, page: 1, pageSize }));
  };

  const handleCSVDownloadButtonClick = (data: ChannelCount[]) => () => {
    const csvHeaders = [
      '일자',
      '신규 채널 수',
      '누적 채널 수',
      '내부 신규 채널 수',
      '내부 누적 채널 수'
    ];

    const csvData = data.map((channelCount) => {
      return [
        channelCount.date,
        numberWithCommas(channelCount.newChannelCount),
        numberWithCommas(channelCount.cumulativeChannelCount),
        numberWithCommas(channelCount.internalNewChannelCount),
        numberWithCommas(channelCount.internalCumulativeChannelCount)
      ];
    });

    new CSVHelper().download(
      csvHeaders,
      csvData,
      `${startAt} ~ ${endAt} 채널 수 통계 (${page}-${totalPage})`
    );
  };

  return (
    <Card>
      <Card.Body>
        <Row className="mb-2">
          <Col className="d-flex justify-content-end">
            <SearchBar startAt={startAt} endAt={endAt} onSearch={handleSearch} />
          </Col>
        </Row>

        {isError ? (
          <Error />
        ) : isLoading ? (
          <Loading />
        ) : (
          <>
            <Row className="mb-2">
              <Col className="d-flex justify-content-end">
                <Button
                  variant="light"
                  onClick={handleCSVDownloadButtonClick(data.channelCountList)}
                >
                  CSV Download
                </Button>
              </Col>
            </Row>

            <Table<ChannelCount>
              columns={columns}
              data={data.channelCountList}
              isFetching={isPreviousData}
              paginationProps={{
                currentPage: page,
                totalPage,
                currentPageSize: pageSize,
                pageSizeList,
                changePageSize(pageSize) {
                  const { value: currnetPagesize } = pageSizeList.find(
                    ({ value }) => value === pageSize
                  ) as PageSize;

                  navigate(
                    generateUrl(
                      channelCountsPath,
                      {},
                      { startAt, endAt, page, pageSize: currnetPagesize }
                    )
                  );
                },
                movePage(value) {
                  if (value <= 0 || value > totalPage) {
                    return;
                  }

                  navigate(
                    generateUrl(channelCountsPath, {}, { startAt, endAt, page: value, pageSize })
                  );
                },
                movePrevPage() {
                  if (page <= 1) {
                    return;
                  }

                  navigate(
                    generateUrl(channelCountsPath, {}, { startAt, endAt, page: page - 1, pageSize })
                  );
                },
                moveNextPage() {
                  if (page >= totalPage) {
                    return;
                  }

                  navigate(
                    generateUrl(channelCountsPath, {}, { startAt, endAt, page: page + 1, pageSize })
                  );
                }
              }}
              theadClass="table-light"
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
}
