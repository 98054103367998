import type {
  GetChannelCpConsumptionAmountRequestPayload,
  GetChannelTotalExpectedProfitRequestPayload,
  GetChannelTotalTransactionAmountDetailsRequestPayload,
  GetChannelTotalTransactionAmountResumptionRequestPayload,
  GetPublEstimatedTotalSalesTopRequestPayload,
  GetPublServiceChargeEstimatedSalesRequestPayload,
  GetPublTotalSalesRequestPayload
} from '@repositories/api/dashboard.types';
import type {
  GetChannelAccessListRequestPayload,
  GetChannelCountListRequestPayload,
  GetDailyActiveUsersListRequestPayload,
  GetMonthlyActiveUsersListRequestPayload,
  GetPAppAdoptionRateListRequestPayload,
  GetSellerCountListRequestPayload
} from '@repositories/api/statistics.types';

export const QUERY_KEY = {
  AUTH: {
    VALIDATE_ACCESS_TOKEN: () => ['AUTH', 'VALIDATE_ACCESS_TOKEN'] as const
  },
  DASHBOARD: {
    GET_PUBL_DAILY_TOTAL_SALES: ({ queryParams }: GetPublTotalSalesRequestPayload) =>
      ['DASHBOARD', 'GET_PUBL_DAILY_TOTAL_SALES', queryParams] as const,
    GET_PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES: ({
      queryParams
    }: GetPublServiceChargeEstimatedSalesRequestPayload) =>
      ['DASHBOARD', 'GET_PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES', queryParams] as const,
    GET_PUBL_DAILY_ESTIMATED_TOTAL_SALES_TOP: ({
      queryParams
    }: GetPublEstimatedTotalSalesTopRequestPayload) =>
      ['DASHBOARD', 'GET_PUBL_DAILY_ESTIMATED_TOTAL_SALES_TOP', queryParams] as const,
    GET_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS: ({
      queryParams
    }: GetChannelTotalTransactionAmountDetailsRequestPayload) =>
      ['DASHBOARD', 'GET_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS', queryParams] as const,
    GET_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION: ({
      queryParams
    }: GetChannelTotalTransactionAmountResumptionRequestPayload) =>
      ['DASHBOARD', 'GET_CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION', queryParams] as const,
    GET_CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT: ({
      queryParams
    }: GetChannelCpConsumptionAmountRequestPayload) =>
      ['DASHBOARD', 'GET_CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT', queryParams] as const,
    GET_CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT: ({
      queryParams
    }: GetChannelTotalExpectedProfitRequestPayload) =>
      ['DASHBOARD', 'GET_CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT', queryParams] as const,
    GET_PUBL_MONTHLY_TOTAL_SALES: ({ queryParams }: GetPublTotalSalesRequestPayload) =>
      ['DASHBOARD', 'GET_PUBL_MONTHLY_TOTAL_SALES', queryParams] as const,
    GET_PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES: ({
      queryParams
    }: GetPublServiceChargeEstimatedSalesRequestPayload) =>
      ['DASHBOARD', 'GET_PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES', queryParams] as const,
    GET_PUBL_MONTHLY_ESTIMATED_TOTAL_SALES_TOP: ({
      queryParams
    }: GetPublEstimatedTotalSalesTopRequestPayload) =>
      ['DASHBOARD', 'GET_PUBL_MONTHLY_ESTIMATED_TOTAL_SALES_TOP', queryParams] as const,
    GET_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS: ({
      queryParams
    }: GetChannelTotalTransactionAmountDetailsRequestPayload) =>
      ['DASHBOARD', 'GET_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS', queryParams] as const,
    GET_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION: ({
      queryParams
    }: GetChannelTotalTransactionAmountResumptionRequestPayload) =>
      [
        'DASHBOARD',
        'GET_CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION',
        queryParams
      ] as const,
    GET_CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT: ({
      queryParams
    }: GetChannelCpConsumptionAmountRequestPayload) =>
      ['DASHBOARD', 'GET_CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT', queryParams] as const,
    GET_CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT: ({
      queryParams
    }: GetChannelTotalExpectedProfitRequestPayload) =>
      ['DASHBOARD', 'GET_CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT', queryParams] as const
  },
  STATISTICS: {
    GET_CHANNEL_COUNT_LIST: ({ queryParams }: GetChannelCountListRequestPayload) =>
      ['STATISTICS', 'GET_CHANNEL_COUNT_LIST', queryParams] as const,
    GET_CHANNEL_ACCESS_LIST: ({ queryParams }: GetChannelAccessListRequestPayload) =>
      ['STATISTICS', 'GET_CHANNEL_ACCESS_LIST', queryParams] as const,
    GET_SELLER_COUNT_LIST: ({ queryParams }: GetSellerCountListRequestPayload) =>
      ['STATISTICS', 'GET_SELLER_COUNT_LIST', queryParams] as const,
    GET_DAILY_ACTIVE_USERS_LIST: ({ queryParams }: GetDailyActiveUsersListRequestPayload) =>
      ['STATISTICS', 'GET_DAILY_ACCESS_USERS_LIST', queryParams] as const,
    GET_MONTHLY_ACTIVE_USERS_LIST: ({ queryParams }: GetMonthlyActiveUsersListRequestPayload) =>
      ['STATISTICS', 'GET_MONTHLY_ACCSEE_USERS_LIST', queryParams] as const,
    GET_P_APP_ADOPTION_RATE_LIST: ({ queryParams }: GetPAppAdoptionRateListRequestPayload) =>
      ['STATISTICS', 'GET_P_APP_ADOPTION_RATE_LIST', queryParams] as const
  }
};
