import { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { DateHelper } from '@utils';

import {
  PublDailyTotalSales,
  HeaderBar,
  PublDailyServiceChargeEstimatedSales,
  PublMonthlyTotalSales,
  PublMonthlyServiceChargeEstimatedSales,
  ChannelDailyTotalTransactionAmountDetails,
  ChannelDailyTotalTransactionAmountResumption,
  ChannelDailyTotalExpectedProfit,
  ChannelMonthlyTotalTransactionAmountDetails,
  ChannelMonthlyTotalTransactionAmountResumption,
  ChannelMonthlyCpConsumptionAmount,
  ChannelMonthlyTotalExpectedProfit
} from './components';
import { ChannelDailyCpConsumptionAmount } from './components/ChannelDailyCpConsumptionAmount';

export const CARD_STATUS = {
  IDLE: 'idle',
  IS_WAITING: 'isWaiting',
  DONE: 'done'
} as const;

export const CARD_NAME = {
  CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS: 'channelDailyTotalTransactionAmountDetails',
  CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS: 'channelMonthlyTotalTransactionAmountDetails',
  CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION: 'channelDailyTotalTransactionAmountResumption',
  CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION:
    'channelMonthlyTotalTransactionAmountResumption',
  CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT: 'channelDailyCpConsumptionAmount',
  CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT: 'channelMonthlyCpConsumptionAmount',
  PUBL_DAILY_TOTAL_SALES: 'publDailyTotalSales',
  PUBL_MONTHLY_TOTAL_SALES: 'publMonthlyTotalSales',
  PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES: 'publDailyServiceChargeEstimatedSales',
  PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES: 'publMonthlyServiceChargeEstimatedSales',
  CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT: 'channelDailyTotalExpectedProfit',
  CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT: 'channelMonthlyTotalExpectedProfit'
} as const;

type CardStatus = Record<
  (typeof CARD_NAME)[keyof typeof CARD_NAME],
  (typeof CARD_STATUS)[keyof typeof CARD_STATUS]
>;

const defaultValue = {
  channelDailyTotalTransactionAmountDetails: CARD_STATUS.IS_WAITING,
  channelMonthlyTotalTransactionAmountDetails: CARD_STATUS.IS_WAITING,
  channelDailyTotalTransactionAmountResumption: CARD_STATUS.IS_WAITING,
  channelMonthlyTotalTransactionAmountResumption: CARD_STATUS.IS_WAITING,
  channelDailyCpConsumptionAmount: CARD_STATUS.IS_WAITING,
  channelMonthlyCpConsumptionAmount: CARD_STATUS.IS_WAITING,
  publDailyTotalSales: CARD_STATUS.IS_WAITING,
  publMonthlyTotalSales: CARD_STATUS.IS_WAITING,
  publDailyServiceChargeEstimatedSales: CARD_STATUS.IS_WAITING,
  publMonthlyServiceChargeEstimatedSales: CARD_STATUS.IS_WAITING,
  channelDailyTotalExpectedProfit: CARD_STATUS.IS_WAITING,
  channelMonthlyTotalExpectedProfit: CARD_STATUS.IS_WAITING
} as const;

export function RevenueFlowEstimation() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [cardStatus, setCardStatus] = useState<CardStatus>(defaultValue);

  const exchangeRate = searchParams.get('exchangeRate');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const startMonth = new DateHelper(startDate || '').format('YYYY-MM');
  const endMonth = new DateHelper(endDate || '').format('YYYY-MM');

  const prevParamsRef = useRef({ exchangeRate, startDate, endDate, startMonth, endMonth });

  useEffect(() => {
    const getNewCardStatus = (cardStatus: (typeof CARD_STATUS)[keyof typeof CARD_STATUS]) => {
      return cardStatus === CARD_STATUS.IS_WAITING ? CARD_STATUS.IS_WAITING : CARD_STATUS.DONE;
    };

    const newCardStatus: CardStatus = {
      channelDailyTotalTransactionAmountDetails: getNewCardStatus(
        cardStatus.channelDailyTotalTransactionAmountDetails
      ),
      channelMonthlyTotalTransactionAmountDetails: getNewCardStatus(
        cardStatus.channelMonthlyTotalTransactionAmountDetails
      ),
      channelDailyTotalTransactionAmountResumption: getNewCardStatus(
        cardStatus.channelDailyTotalTransactionAmountResumption
      ),
      channelMonthlyTotalTransactionAmountResumption: getNewCardStatus(
        cardStatus.channelMonthlyTotalTransactionAmountResumption
      ),
      channelDailyCpConsumptionAmount: getNewCardStatus(cardStatus.channelDailyCpConsumptionAmount),
      channelMonthlyCpConsumptionAmount: getNewCardStatus(
        cardStatus.channelMonthlyCpConsumptionAmount
      ),
      publDailyTotalSales: getNewCardStatus(cardStatus.publDailyTotalSales),
      publMonthlyTotalSales: getNewCardStatus(cardStatus.publMonthlyTotalSales),
      publDailyServiceChargeEstimatedSales: getNewCardStatus(
        cardStatus.publDailyServiceChargeEstimatedSales
      ),
      publMonthlyServiceChargeEstimatedSales: getNewCardStatus(
        cardStatus.publMonthlyServiceChargeEstimatedSales
      ),
      channelDailyTotalExpectedProfit: getNewCardStatus(cardStatus.channelDailyTotalExpectedProfit),
      channelMonthlyTotalExpectedProfit: getNewCardStatus(
        cardStatus.channelMonthlyTotalExpectedProfit
      )
    };

    if (prevParamsRef.current.exchangeRate !== exchangeRate) {
      newCardStatus.channelDailyTotalTransactionAmountResumption = CARD_STATUS.IS_WAITING;
      newCardStatus.channelMonthlyTotalTransactionAmountResumption = CARD_STATUS.IS_WAITING;
      newCardStatus.publDailyTotalSales = CARD_STATUS.IS_WAITING;
      newCardStatus.publMonthlyTotalSales = CARD_STATUS.IS_WAITING;
      newCardStatus.publDailyServiceChargeEstimatedSales = CARD_STATUS.IS_WAITING;
      newCardStatus.publMonthlyServiceChargeEstimatedSales = CARD_STATUS.IS_WAITING;
      newCardStatus.channelDailyTotalExpectedProfit = CARD_STATUS.IS_WAITING;
      newCardStatus.channelMonthlyTotalExpectedProfit = CARD_STATUS.IS_WAITING;
    }

    if (
      prevParamsRef.current.startDate !== startDate ||
      prevParamsRef.current.endDate !== endDate
    ) {
      newCardStatus.channelDailyCpConsumptionAmount = CARD_STATUS.IS_WAITING;
      newCardStatus.channelDailyTotalTransactionAmountDetails = CARD_STATUS.IS_WAITING;
      newCardStatus.channelDailyTotalTransactionAmountResumption = CARD_STATUS.IS_WAITING;
      newCardStatus.publDailyTotalSales = CARD_STATUS.IS_WAITING;
      newCardStatus.publDailyServiceChargeEstimatedSales = CARD_STATUS.IS_WAITING;
      newCardStatus.channelDailyTotalExpectedProfit = CARD_STATUS.IS_WAITING;
    }

    if (
      prevParamsRef.current.startMonth !== startMonth ||
      prevParamsRef.current.endMonth !== endMonth
    ) {
      newCardStatus.channelMonthlyCpConsumptionAmount = CARD_STATUS.IS_WAITING;
      newCardStatus.channelMonthlyTotalTransactionAmountDetails = CARD_STATUS.IS_WAITING;
      newCardStatus.channelMonthlyTotalTransactionAmountResumption = CARD_STATUS.IS_WAITING;
      newCardStatus.publMonthlyTotalSales = CARD_STATUS.IS_WAITING;
      newCardStatus.publMonthlyServiceChargeEstimatedSales = CARD_STATUS.IS_WAITING;
      newCardStatus.channelMonthlyTotalExpectedProfit = CARD_STATUS.IS_WAITING;
    }

    if (Object.values(newCardStatus).every((status) => status === CARD_STATUS.DONE)) {
      return;
    }

    prevParamsRef.current = { exchangeRate, startDate, endDate, startMonth, endMonth };

    setCardStatus(newCardStatus);
  }, [exchangeRate, startDate, endDate, startMonth, endMonth]);

  useEffect(() => {
    const isAllEnabled = Object.values(cardStatus).every((status) => status === CARD_STATUS.DONE);

    if (isAllEnabled) {
      setCardStatus({
        channelDailyTotalTransactionAmountDetails: CARD_STATUS.IDLE,
        channelMonthlyTotalTransactionAmountDetails: CARD_STATUS.IDLE,
        channelDailyTotalTransactionAmountResumption: CARD_STATUS.IDLE,
        channelMonthlyTotalTransactionAmountResumption: CARD_STATUS.IDLE,
        channelDailyCpConsumptionAmount: CARD_STATUS.IDLE,
        channelMonthlyCpConsumptionAmount: CARD_STATUS.IDLE,
        publDailyTotalSales: CARD_STATUS.IDLE,
        publMonthlyTotalSales: CARD_STATUS.IDLE,
        publDailyServiceChargeEstimatedSales: CARD_STATUS.IDLE,
        publMonthlyServiceChargeEstimatedSales: CARD_STATUS.IDLE,
        channelDailyTotalExpectedProfit: CARD_STATUS.IDLE,
        channelMonthlyTotalExpectedProfit: CARD_STATUS.IDLE
      });
    }
  }, [cardStatus]);

  const isInvalidParams = exchangeRate === null || startDate === null || endDate === null;

  const handleFetchComplete = (type: keyof typeof cardStatus) => () => {
    setCardStatus((prev) => ({ ...prev, [type]: CARD_STATUS.DONE }));
  };

  const checkEnabled = (currentType: keyof CardStatus, cardTypeList: (keyof CardStatus)[]) => {
    return (
      cardStatus[currentType] === CARD_STATUS.IS_WAITING &&
      cardTypeList.every((type) => {
        return cardStatus[type] === CARD_STATUS.DONE;
      })
    );
  };

  return (
    <>
      <Row>
        <Col>
          <HeaderBar />
        </Col>
      </Row>

      {!isInvalidParams && (
        <>
          <Row className="mt-3">
            <Col>
              <ChannelDailyTotalTransactionAmountDetails
                startAt={startDate}
                endAt={endDate}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  []
                )}
                onFetchComplete={handleFetchComplete(
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ChannelMonthlyTotalTransactionAmountDetails
                startAt={startMonth}
                endAt={endMonth}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  [CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS]
                )}
                onFetchComplete={handleFetchComplete(
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ChannelDailyTotalTransactionAmountResumption
                startAt={startDate}
                endAt={endDate}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  [
                    CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                    CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS
                  ]
                )}
                onFetchComplete={handleFetchComplete(
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ChannelMonthlyTotalTransactionAmountResumption
                startAt={startMonth}
                endAt={endMonth}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  [
                    CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                    CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                    CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION
                  ]
                )}
                onFetchComplete={handleFetchComplete(
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ChannelDailyCpConsumptionAmount
                startAt={startDate}
                endAt={endDate}
                isEnabled={checkEnabled(CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT, [
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION
                ])}
                onFetchComplete={handleFetchComplete(CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ChannelMonthlyCpConsumptionAmount
                startAt={startMonth}
                endAt={endMonth}
                isEnabled={checkEnabled(CARD_NAME.CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT, [
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT
                ])}
                onFetchComplete={handleFetchComplete(
                  CARD_NAME.CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <PublDailyTotalSales
                startAt={startDate}
                endAt={endDate}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(CARD_NAME.PUBL_DAILY_TOTAL_SALES, [
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT
                ])}
                onFetchComplete={handleFetchComplete(CARD_NAME.PUBL_DAILY_TOTAL_SALES)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <PublMonthlyTotalSales
                startAt={startMonth}
                endAt={endMonth}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(CARD_NAME.PUBL_MONTHLY_TOTAL_SALES, [
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.PUBL_DAILY_TOTAL_SALES
                ])}
                onFetchComplete={handleFetchComplete(CARD_NAME.PUBL_MONTHLY_TOTAL_SALES)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <PublDailyServiceChargeEstimatedSales
                startAt={startDate}
                endAt={endDate}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(CARD_NAME.PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES, [
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.PUBL_DAILY_TOTAL_SALES,
                  CARD_NAME.PUBL_MONTHLY_TOTAL_SALES
                ])}
                onFetchComplete={handleFetchComplete(
                  CARD_NAME.PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <PublMonthlyServiceChargeEstimatedSales
                startAt={startMonth}
                endAt={endMonth}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(CARD_NAME.PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES, [
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.PUBL_DAILY_TOTAL_SALES,
                  CARD_NAME.PUBL_MONTHLY_TOTAL_SALES,
                  CARD_NAME.PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES
                ])}
                onFetchComplete={handleFetchComplete(
                  CARD_NAME.PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES
                )}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ChannelDailyTotalExpectedProfit
                startAt={startDate}
                endAt={endDate}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(CARD_NAME.CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT, [
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.PUBL_DAILY_TOTAL_SALES,
                  CARD_NAME.PUBL_MONTHLY_TOTAL_SALES,
                  CARD_NAME.PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES,
                  CARD_NAME.PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES
                ])}
                onFetchComplete={handleFetchComplete(CARD_NAME.CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <ChannelMonthlyTotalExpectedProfit
                startAt={startMonth}
                endAt={endMonth}
                exchangeRate={exchangeRate}
                isEnabled={checkEnabled(CARD_NAME.CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT, [
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_DETAILS,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_TRANSACTION_AMOUNT_RESUMPTION,
                  CARD_NAME.CHANNEL_DAILY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.CHANNEL_MONTHLY_CP_CONSUMPTION_AMOUNT,
                  CARD_NAME.PUBL_DAILY_TOTAL_SALES,
                  CARD_NAME.PUBL_MONTHLY_TOTAL_SALES,
                  CARD_NAME.PUBL_DAILY_SERVICE_CHARGE_ESTIMATED_SALES,
                  CARD_NAME.PUBL_MONTHLY_SERVICE_CHARGE_ESTIMATED_SALES,
                  CARD_NAME.CHANNEL_DAILY_TOTAL_EXPECTED_PROFIT
                ])}
                onFetchComplete={handleFetchComplete(
                  CARD_NAME.CHANNEL_MONTHLY_TOTAL_EXPECTED_PROFIT
                )}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
