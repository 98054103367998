import { QueryKey as OriginQueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QUERY_KEY } from '@query/keys';
import { ApiRepository } from '@repositories/api';

import type {
  GetPublTotalSalesRequestPayload,
  PublTotalSalesResponseOK,
  PublTotalSalesResponseError
} from '@repositories/api/dashboard.types';
import type { AxiosResponse } from 'axios';

type Props<Data extends AxiosResponse, ErrorResponse, QueryKey extends OriginQueryKey> = {
  queryParams: GetPublTotalSalesRequestPayload['queryParams'];
  options?: Omit<
    UseQueryOptions<Data, ErrorResponse, Data['data']['data'], QueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  >;
};

function useGetPublMonthlyTotalSalesQuery<
  Data extends PublTotalSalesResponseOK,
  ErrorResponse extends PublTotalSalesResponseError
>({
  queryParams,
  options
}: Props<
  Data,
  ErrorResponse,
  ReturnType<typeof QUERY_KEY.DASHBOARD.GET_PUBL_MONTHLY_TOTAL_SALES>
>) {
  return useQuery({
    queryKey: QUERY_KEY.DASHBOARD.GET_PUBL_MONTHLY_TOTAL_SALES({ queryParams }),
    queryFn: () => {
      return ApiRepository.dashboard.getPublMonthlyTotalSales({ queryParams });
    },
    select: (data) => data.data.data,
    ...options
  });
}

export { useGetPublMonthlyTotalSalesQuery };
