import { Card, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { dailySellerChannelAccessPath } from '@appConstants';
import { Error, Loading, PageSize, Table } from '@components';
import { useGetChannelAccessListQuery } from '@query/statistics/hooks';
import { CSVHelper } from '@utils/csvHelper';
import { generateUrl } from '@utils/generateUrl';

import { SearchBar } from './SearchBar';

import type { ChannelAccess } from '@repositories/api/statistics.types';
import type { Column } from 'react-table';

type Props = {
  channelId: string | null;
  startAt: string;
  endAt: string;
  page: number;
  pageSize: number;
  pageSizeList: PageSize[];
};

const columns: ReadonlyArray<Column> = [
  {
    Header: () => <div className="text-center">일자</div>,
    accessor: 'date',
    Cell: ({ value }) => <div className="text-center">{value}</div>
  },
  {
    Header: <div className="text-center">접근 여부</div>,
    accessor: 'isAccessed',
    Cell: ({ value }) => <div className="text-center">{String(value || '-')}</div>
  }
];

export function DailySellerChannelAccessCard({
  channelId,
  startAt,
  endAt,
  page,
  pageSize,
  pageSizeList
}: Props) {
  const { data, isLoading, isPreviousData, isError } = useGetChannelAccessListQuery({
    queryParams: {
      startAt,
      endAt,
      channelId: channelId || '',
      page,
      limit: pageSize,
      orderBy: 'DESC'
    },
    options: {
      keepPreviousData: true,
      enabled: !!channelId
    }
  });

  const isDisabled = isLoading && !isPreviousData;

  const navigate = useNavigate();
  const totalPage = Math.ceil((data?.pagination.totalEntry || 1) / pageSize);

  const handleSearch = (data: { channelId: string; startAt: string; endAt: string }) => {
    const { channelId, startAt, endAt } = data;

    navigate(
      generateUrl(
        dailySellerChannelAccessPath,
        {},
        { channelId, startAt, endAt, page: 1, pageSize }
      )
    );
  };

  const handleCSVDownloadButtonClick = (data: ChannelAccess[]) => () => {
    const csvHeaders = ['일자', '접근 여부'];

    const csvData = data.map((channelAccess) => {
      return [channelAccess.date, String(channelAccess.isAccessed || '-')];
    });

    new CSVHelper().download(
      csvHeaders,
      csvData,
      `${startAt} ~ ${endAt} 일별 셀러 채널 접근 통계 (${page}-${totalPage})`
    );
  };

  return (
    <Card>
      <Card.Body>
        <Row className="mb-2">
          <Col className="d-flex justify-content-end">
            <SearchBar
              channelId={channelId}
              startAt={startAt}
              endAt={endAt}
              onSearch={handleSearch}
            />
          </Col>
        </Row>

        {isError ? (
          <Error />
        ) : isDisabled ? (
          <h4 className="text-center mt-5 mb-4">채널 아이디를 입력해 주세요</h4>
        ) : isLoading ? (
          <Loading />
        ) : (
          <>
            <Row className="mb-2">
              <Col className="d-flex justify-content-end">
                <Button
                  variant="light"
                  onClick={handleCSVDownloadButtonClick(data.channelAccessList)}
                >
                  CSV Download
                </Button>
              </Col>
            </Row>

            <Table<ChannelAccess>
              columns={columns}
              data={data.channelAccessList}
              isFetching={isPreviousData}
              paginationProps={{
                currentPage: page,
                totalPage,
                currentPageSize: pageSize,
                pageSizeList,
                changePageSize(pageSize) {
                  const { value: currnetPagesize } = pageSizeList.find(
                    ({ value }) => value === pageSize
                  ) as PageSize;

                  navigate(
                    generateUrl(
                      dailySellerChannelAccessPath,
                      {},
                      { channelId, startAt, endAt, page, pageSize: currnetPagesize }
                    )
                  );
                },
                movePage(value) {
                  if (value <= 0 || value > totalPage) {
                    return;
                  }

                  navigate(
                    generateUrl(
                      dailySellerChannelAccessPath,
                      {},
                      { channelId, startAt, endAt, page: value, pageSize }
                    )
                  );
                },
                movePrevPage() {
                  if (page <= 1) {
                    return;
                  }

                  navigate(
                    generateUrl(
                      dailySellerChannelAccessPath,
                      {},
                      { channelId, startAt, endAt, page: page - 1, pageSize }
                    )
                  );
                },
                moveNextPage() {
                  if (page >= totalPage) {
                    return;
                  }

                  navigate(
                    generateUrl(
                      dailySellerChannelAccessPath,
                      {},
                      { channelId, startAt, endAt, page: page + 1, pageSize }
                    )
                  );
                }
              }}
              theadClass="table-light"
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
}
