import { generateRoutePath } from '@utils/generateRoutePath';

import { PATHNAMES } from './pathnames';

export const signInPath = PATHNAMES.SIGN_IN;

export const oauthPath = PATHNAMES.OAUTH;
export const oauthVerifyPath = generateRoutePath(oauthPath, PATHNAMES.VERIFY);

export const dashboardsPath = PATHNAMES.DASHBOARDS;

export const revenueFlowEstimationPath = generateRoutePath(
  dashboardsPath,
  PATHNAMES.REVENUE_FLOW_ESTIMATION
);

export const statisticsPath = generateRoutePath(PATHNAMES.DASHBOARDS, PATHNAMES.STATISTICS);
export const channelCountsPath = generateRoutePath(statisticsPath, PATHNAMES.CHANNEL_COUNTS);
export const sellerCountsPath = generateRoutePath(statisticsPath, PATHNAMES.SELLER_COUNTS);
export const dailyActiveUsersPath = generateRoutePath(statisticsPath, PATHNAMES.DAU);
export const monthlyActiveUsersPath = generateRoutePath(statisticsPath, PATHNAMES.MAU);
export const dailySellerChannelAccessPath = generateRoutePath(
  statisticsPath,
  PATHNAMES.DAILY_SELLER_CHANNEL_ACCESS
);
export const pAppAdoptionRatePath = generateRoutePath(
  statisticsPath,
  PATHNAMES.P_APP_ADOPTION_RATE
);
