import { useEffect } from 'react';
import { Card } from 'react-bootstrap';

import { Error, Loading } from '@components';
import { useGetChannelDailyCpConsumptionAmountQuery } from '@query/dashboard/hooks';
import { numberWithCommas } from '@utils';

import { DataTable } from './DataTable';
import { Tabs } from './Tabs';

type Props = {
  startAt: string;
  endAt: string;
  isEnabled: boolean;
  onFetchComplete: () => void;
};

export function ChannelDailyCpConsumptionAmount({
  startAt,
  endAt,
  isEnabled,
  onFetchComplete
}: Props) {
  const { data, isLoading, isFetching, isError, refetch } =
    useGetChannelDailyCpConsumptionAmountQuery({
      queryParams: {
        startAt,
        endAt
      },
      options: {
        enabled: isEnabled,
        onSettled: onFetchComplete
      }
    });

  useEffect(() => {
    if (isEnabled) {
      refetch();
    }
  }, [isEnabled]);

  return (
    <Card>
      <Card.Body>
        <Tabs
          title="일별 채널 거래액 : CP 소비액"
          contentsHeight="400px"
          defaultActivateTab="DATA_TABLE"
          tabItems={[
            {
              id: 'BAR_CHART',
              title: 'Bar Chart',
              content: <></>,
              status: 'disabled'
            },
            {
              id: 'DATA_TABLE',
              title: 'Data Table',
              content: (
                <>
                  {isError ? (
                    <Error />
                  ) : isLoading || isFetching ? (
                    <Loading />
                  ) : (
                    <DataTable
                      data={data.cpConsumptionAmountList}
                      columns={[
                        {
                          header: '날짜',
                          field: 'date',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: 'CP 충전 총량 (B)',
                          field: 'CHANNEL_POINT_CHARGE',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: 'CP 소비 총량 (C)',
                          field: 'CHANNEL_POINT_USAGE',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: 'CP 환불 총량 (D)',
                          field: 'CHANNEL_POINT_USAGE_REFUND',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: 'CP 소비 정산량 (C-D)',
                          field: 'CONSUMPTION_SETTLEMENT_AMOUNT',
                          formatFiled: (value) => numberWithCommas(value)
                        },
                        {
                          header: 'CP 잔여 총량 (B+C-D)',
                          field: 'REMAINING_TOTAL_AMOUNT',
                          formatFiled: (value) => numberWithCommas(value)
                        }
                      ]}
                    />
                  )}
                </>
              )
            }
          ]}
        />
      </Card.Body>
    </Card>
  );
}
