import type { BarChartDatum } from '../components/BarChart';

export const generateBarChartData = ({
  type,
  keys,
  labels,
  data
}: {
  type: string;
  keys: string[];
  labels: Record<string, string>;
  data: Record<string, string>[];
}): BarChartDatum[] => {
  const barChartData: BarChartDatum[] = keys.map((key) => ({
    name: key,
    type,
    data: []
  }));

  barChartData.forEach((barChartDatum) => {
    data.forEach((datum) => {
      barChartDatum.data.push({ x: datum.date, y: datum[barChartDatum.name] });
    });

    barChartDatum.name = labels[barChartDatum.name];
  });

  return barChartData;
};
