type Data = string[][];
type Headers = string[];

export class CSVHelper {
  type = 'text/csv';
  separator = ',';
  enclosingCharacter = '"';

  /**
   * @param headers ['header1', 'header2']
   * @param data [['data1', 'data2'], ['data3', 'data4']]
   * @returns '"header1","header2"\n"data1","data2"\n"data3","data4"'
   */
  toCSV(headers: Headers, data: Data) {
    return [headers, ...data]
      .map((row) =>
        row
          .map((column) => `${this.enclosingCharacter}${column}${this.enclosingCharacter}`)
          .join(this.separator)
      )
      .join(`\n`);
  }

  /**
   * @param headers 배열 형태의 헤더 리스트
   * @param data 이중 배열 형태의 데이터 리스트
   */
  download(headers: Headers, data: Data, filename: string) {
    const csv = this.toCSV(headers, data);
    const csvData = new Blob(['\ufeff', csv], { type: this.type });

    const csvURL = window.URL.createObjectURL(csvData);

    const link = document.createElement('a');

    link.setAttribute('href', csvURL);
    link.setAttribute('download', `${filename}.csv`);
    link.click();
    link.remove();
  }
}
