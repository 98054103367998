import { Row, Col } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

import { dashboardsPath, pAppAdoptionRatePath, statisticsPath } from '@appConstants';
import { PageTitle } from '@components';
import { DateHelper } from '@utils/DateHelper';
import { generateUrl } from '@utils/generateUrl';

import { PAppAdoptionRateCard } from './components';
import { useQueryParams } from './hooks';

const breadcrumbItems = [
  {
    label: 'Dashboards',
    path: dashboardsPath,
    active: false
  },
  {
    label: '통계',
    path: statisticsPath,
    active: false
  },
  {
    label: 'pApp 채택률',
    path: pAppAdoptionRatePath,
    active: true
  }
];

export function PAppAdoptionRate() {
  const { date, isInValidQueryParams } = useQueryParams();

  if (isInValidQueryParams) {
    const yesterday = new DateHelper().subtract(1, 'D').format('YYYY-MM-DD');
    const defaultQueryParams = {
      date: yesterday
    };

    return <Navigate replace to={generateUrl(pAppAdoptionRatePath, {}, defaultQueryParams)} />;
  }

  return (
    <>
      <PageTitle title="pApp 채택률" breadCrumbItems={breadcrumbItems} />

      <Row>
        <Col xs={12}>
          <PAppAdoptionRateCard date={date} />
        </Col>
      </Row>
    </>
  );
}
