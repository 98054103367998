/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { useRef, useEffect, forwardRef, useState } from 'react';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  Column,
  Row,
  FilterValue
} from 'react-table';

import { Pagination, PaginationProps } from './Pagination';

export type CellFormatter<T extends object = object> = {
  row: Row<T>;
};

type GlobalFilterProps = {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: (filterValue: FilterValue) => void;
  searchBoxClass?: string;
};

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchBoxClass
}: GlobalFilterProps) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState<any>(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className={classNames(searchBoxClass)}>
      <span className="d-flex align-items-center">
        Search :{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          className="form-control w-auto ms-1"
        />
      </span>
    </div>
  );
}

type IndeterminateCheckboxProps = {
  indeterminate: any;
  children?: React.ReactNode;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="form-check">
        <input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
        <label htmlFor="form-check-input" className="form-check-label" />
      </div>
    );
  }
);
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

type TableProps<TableValues> = {
  isSearchable?: boolean;
  isSortable?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  isFetching?: boolean;
  columns: ReadonlyArray<Column>;
  data: TableValues[];
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  paginationProps?: PaginationProps;
};

function Table<TableValues extends object = object>({
  isSearchable = false,
  isSortable = false,
  isSelectable = false,
  isExpandable = false,
  isFetching = false,
  columns,
  data,
  searchBoxClass,
  tableClass,
  theadClass,
  paginationProps
}: TableProps<TableValues>) {
  const otherProps: {
    useGlobalFilter?: any;
    useSortBy?: any;
    useExpanded?: any;
    usePagination?: any;
    useRowSelect?: any;
  } = {};

  if (isSearchable) {
    otherProps.useGlobalFilter = useGlobalFilter;
  }
  if (isSortable) {
    otherProps.useSortBy = useSortBy;
  }
  if (isExpandable) {
    otherProps.useExpanded = useExpanded;
  }
  if (isSelectable) {
    otherProps.useRowSelect = useRowSelect;
  }

  const dataTable = useTable(
    {
      columns,
      data
    },

    otherProps.useGlobalFilter ? otherProps.useGlobalFilter : false,
    otherProps.useSortBy ? otherProps.useSortBy : false,
    otherProps.useExpanded ? otherProps.useExpanded : false,
    otherProps.useRowSelect ? otherProps.useRowSelect : false,

    (hooks) => {
      isSelectable &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            // eslint-disable-next-line react/no-unstable-nested-components
            Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: ({ row }: any) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns
        ]);

      isExpandable &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            // Build our expander column
            id: 'expander', // Make sure it has an ID
            // eslint-disable-next-line react/no-unstable-nested-components
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
              <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? '-' : '+'}</span>
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: ({ row }) =>
              // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
              // to build the toggle for expanding a row
              row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps({
                    style: {
                      // We can even use the row.depth property
                      // and paddingLeft to indicate the depth
                      // of the row
                      paddingLeft: `${row.depth * 2}rem`
                    }
                  })}
                >
                  {row.isExpanded ? '-' : '+'}
                </span>
              ) : null
          },
          ...columns
        ]);
    }
  );

  const { rows } = dataTable;

  return (
    <>
      {isSearchable && (
        <GlobalFilter
          preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
          globalFilter={dataTable.state.globalFilter}
          setGlobalFilter={dataTable.setGlobalFilter}
          searchBoxClass={searchBoxClass}
        />
      )}

      <div className="table-responsive">
        <table
          {...dataTable.getTableProps()}
          className={classNames('table table-centered react-table', tableClass)}
        >
          <thead className={theadClass}>
            {dataTable.headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps(
                      column.defaultCanSort && column.getSortByToggleProps()
                    )}
                    className={classNames({
                      sorting_desc: column.isSortedDesc === true,
                      sorting_asc: column.isSortedDesc === false,
                      sortable: column.defaultCanSort === true
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...dataTable.getTableBodyProps()}
            className={classNames('position-relative', {
              'placeholder-glow': isFetching
            })}
          >
            {isFetching && <div className="placeholder position-absolute w-100 h-100 fade" />}
            {(rows || []).map((row) => {
              dataTable.prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    // eslint-disable-next-line react/jsx-key
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {paginationProps && <Pagination {...paginationProps} />}
    </>
  );
}

export { Table };
