import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { ApiRepository } from '@repositories/api';
import api from '@services/api';

import type {
  IssueTwoFactorAccessTokenPayload,
  IssueTwoFactorAccessTokenResponseError,
  IssueTwoFactorAccessTokenResponseOK
} from '@repositories/api/auth.types';

function useSignInMutation(): UseMutationResult<
  IssueTwoFactorAccessTokenResponseOK,
  IssueTwoFactorAccessTokenResponseError,
  IssueTwoFactorAccessTokenPayload
> {
  return useMutation({
    mutationFn: ApiRepository.auth.issueTwoFactorAccessToken,
    onMutate({ header }) {
      api.applyCredential({ token: header.accessToken });
    },
    onError() {
      api.purgeCredential();
    },
    onSuccess(data) {
      if ('data' in data.data) {
        api.applyCredential({ token: data.data.data.accessToken });
      }
    }
  });
}

export { useSignInMutation };
