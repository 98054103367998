import api, { getEndpointSet } from '@services/api';

import type { IssueTwoFactorAccessTokenPayload } from './auth.types';

const {
  GET_TWO_FACTOR_ACCESS_TOKEN,
  UPDATE_TWO_FACTOR_ACCESS_TOKEN,
  VALIDATE_TWO_FACTOR_ACCESS_TOKEN
} = getEndpointSet('HUB', 'oauth');

class AuthRepository {
  static issueTwoFactorAccessToken({ body }: IssueTwoFactorAccessTokenPayload) {
    return api.post(GET_TWO_FACTOR_ACCESS_TOKEN, {}, body);
  }

  static reissueTwoFactorAccessToken() {
    return api.post(UPDATE_TWO_FACTOR_ACCESS_TOKEN);
  }

  static validateTwoFactorAccessToken() {
    return api.get(VALIDATE_TWO_FACTOR_ACCESS_TOKEN);
  }
}

export default AuthRepository;
