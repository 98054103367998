import { atom } from 'jotai';

export const initialState = {
  isAutoSignInExecuted: false,
  isAuthenticated: false
};

export const authAtom = {
  isAutoSignInExecuted: atom(initialState.isAutoSignInExecuted),
  isAuthenticated: atom(initialState.isAuthenticated)
};
