import jwtDecode from 'jwt-decode';

import type { JwtData } from './jwt.types';

class JWT {
  private static safeJwtDecode(token: string) {
    try {
      const jwtData = jwtDecode<JwtData>(token);

      return { jwtData };
    } catch (error) {
      return { jwtError: error };
    }
  }

  private static isJwtTokenExpired(jwtData: JwtData) {
    const { exp } = jwtData;

    return Date.now() / 1000 + 15 > exp;
  }

  static willJwtTokenBeExpired(jwtData: JwtData, threshold: number) {
    const { exp } = jwtData;

    return Date.now() / 1000 + threshold > exp;
  }

  static validateJwtToken(token: string | null) {
    if (!token) {
      return { status: 'ERROR', message: 'TOKEN_NOT_EXIST' } as const;
    }

    const { jwtError, jwtData } = this.safeJwtDecode(token);

    if (!jwtData || jwtError) {
      return { status: 'ERROR', message: 'INVALID_TOKEN' } as const;
    }

    if (this.isJwtTokenExpired(jwtData)) {
      return { status: 'ERROR', message: 'TOKEN_EXPIRED' } as const;
    }

    return { status: 'OK', token, jwtData } as const;
  }

  static getTokenExpirationTime(token: string | null) {
    const { status, jwtData, message } = this.validateJwtToken(token);
    if (status === 'ERROR') {
      return { status, message };
    }

    const expirationTime = jwtData.exp * 1000;

    return { status, expirationTime };
  }
}

export default JWT;
